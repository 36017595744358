var ns = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
};
function as(e) {
  return e;
}
function W(e, t, r, n, a, s, o, c, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return function() {
        return t(e.apply(this, arguments));
      };
    case 3:
      return function() {
        return r(t(e.apply(this, arguments)));
      };
    case 4:
      return function() {
        return n(r(t(e.apply(this, arguments))));
      };
    case 5:
      return function() {
        return a(n(r(t(e.apply(this, arguments)))));
      };
    case 6:
      return function() {
        return s(a(n(r(t(e.apply(this, arguments))))));
      };
    case 7:
      return function() {
        return o(s(a(n(r(t(e.apply(this, arguments)))))));
      };
    case 8:
      return function() {
        return c(o(s(a(n(r(t(e.apply(this, arguments))))))));
      };
    case 9:
      return function() {
        return l(c(o(s(a(n(r(t(e.apply(this, arguments)))))))));
      };
  }
}
function g(e, t, r, n, a, s, o, c, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return t(e);
    case 3:
      return r(t(e));
    case 4:
      return n(r(t(e)));
    case 5:
      return a(n(r(t(e))));
    case 6:
      return s(a(n(r(t(e)))));
    case 7:
      return o(s(a(n(r(t(e))))));
    case 8:
      return c(o(s(a(n(r(t(e)))))));
    case 9:
      return l(c(o(s(a(n(r(t(e))))))));
    default: {
      for (var u = arguments[0], h = 1; h < arguments.length; h++)
        u = arguments[h](u);
      return u;
    }
  }
}
var ss = function(e, t) {
  var r = typeof e == "number" ? function(n) {
    return n.length >= e;
  } : e;
  return function() {
    var n = Array.from(arguments);
    return r(arguments) ? t.apply(this, n) : function(a) {
      return t.apply(void 0, ns([a], n, !1));
    };
  };
}, is = function(e) {
  return {
    equals: function(t, r) {
      return t === r || e(t, r);
    }
  };
}, os = function(e) {
  return is(function(t, r) {
    for (var n in e)
      if (!e[n].equals(t[n], r[n]))
        return !1;
    return !0;
  });
};
globalThis && globalThis.__spreadArray;
var In = function(e) {
  return e._tag === "Some";
}, Nn = { _tag: "None" }, Pn = function(e) {
  return { _tag: "Some", value: e };
}, cs = function(e) {
  return e._tag === "Left";
}, us = function(e) {
  return e._tag === "Right";
}, ls = function(e) {
  return { _tag: "Left", left: e };
}, ds = function(e) {
  return { _tag: "Right", right: e };
}, fs = function(e) {
  return [e];
}, ps = [], hs = Object.prototype.hasOwnProperty, vs = function(e) {
  return function(t) {
    return function(r) {
      return r.reduce(function(n, a) {
        return e.concat(n, a);
      }, t);
    };
  };
}, ys = function(e) {
  return {
    concat: function() {
      return e;
    }
  };
}, gs = function(e) {
  return {
    concat: function(t, r) {
      var n = {};
      for (var a in e)
        hs.call(e, a) && (n[a] = e[a].concat(t[a], r[a]));
      return n;
    }
  };
}, Lt = function() {
  return { concat: as };
}, ms = function() {
  return { concat: function(e, t) {
    return t;
  } };
}, _s = vs, bs = ys(void 0), Zr = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
}, Ts = ps, $s = function(e, t) {
  return e < 0 || e >= t.length;
}, As = function(e) {
  return function(t) {
    return Zr(Zr([], t, !0), [e], !1);
  };
}, ws = As, Ss = fs, F = {
  equals: function(e, t) {
    return e === t;
  }
}, xs = {
  concat: function(e, t) {
    return e + t;
  }
}, Es = "", tr = {
  concat: xs.concat,
  empty: Es
};
F.equals;
var Ln = function(e) {
  return e.trim();
}, Mn = function(e) {
  return e.length === 0;
}, Ye = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Vn(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function Os() {
  this.__data__ = [], this.size = 0;
}
var ks = Os;
function js(e, t) {
  return e === t || e !== e && t !== t;
}
var rr = js, Cs = rr;
function Rs(e, t) {
  for (var r = e.length; r--; )
    if (Cs(e[r][0], t))
      return r;
  return -1;
}
var _t = Rs, Is = _t, Ns = Array.prototype, Ps = Ns.splice;
function Ls(e) {
  var t = this.__data__, r = Is(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : Ps.call(t, r, 1), --this.size, !0;
}
var Ms = Ls, Vs = _t;
function Zs(e) {
  var t = this.__data__, r = Vs(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var Ds = Zs, Us = _t;
function qs(e) {
  return Us(this.__data__, e) > -1;
}
var Hs = qs, Bs = _t;
function Gs(e, t) {
  var r = this.__data__, n = Bs(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
var zs = Gs, Ks = ks, Fs = Ms, Qs = Ds, Ws = Hs, Js = zs;
function be(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
be.prototype.clear = Ks;
be.prototype.delete = Fs;
be.prototype.get = Qs;
be.prototype.has = Ws;
be.prototype.set = Js;
var bt = be, Ys = bt;
function Xs() {
  this.__data__ = new Ys(), this.size = 0;
}
var ei = Xs;
function ti(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var ri = ti;
function ni(e) {
  return this.__data__.get(e);
}
var ai = ni;
function si(e) {
  return this.__data__.has(e);
}
var ii = si, oi = typeof Ye == "object" && Ye && Ye.Object === Object && Ye, Zn = oi, ci = Zn, ui = typeof self == "object" && self && self.Object === Object && self, li = ci || ui || Function("return this")(), G = li, di = G, fi = di.Symbol, Tt = fi, Dr = Tt, Dn = Object.prototype, pi = Dn.hasOwnProperty, hi = Dn.toString, xe = Dr ? Dr.toStringTag : void 0;
function vi(e) {
  var t = pi.call(e, xe), r = e[xe];
  try {
    e[xe] = void 0;
    var n = !0;
  } catch {
  }
  var a = hi.call(e);
  return n && (t ? e[xe] = r : delete e[xe]), a;
}
var yi = vi, gi = Object.prototype, mi = gi.toString;
function _i(e) {
  return mi.call(e);
}
var bi = _i, Ur = Tt, Ti = yi, $i = bi, Ai = "[object Null]", wi = "[object Undefined]", qr = Ur ? Ur.toStringTag : void 0;
function Si(e) {
  return e == null ? e === void 0 ? wi : Ai : qr && qr in Object(e) ? Ti(e) : $i(e);
}
var $t = Si;
function xi(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var Ue = xi, Ei = $t, Oi = Ue, ki = "[object AsyncFunction]", ji = "[object Function]", Ci = "[object GeneratorFunction]", Ri = "[object Proxy]";
function Ii(e) {
  if (!Oi(e))
    return !1;
  var t = Ei(e);
  return t == ji || t == Ci || t == ki || t == Ri;
}
var Un = Ii, Ni = G, Pi = Ni["__core-js_shared__"], Li = Pi, Mt = Li, Hr = function() {
  var e = /[^.]+$/.exec(Mt && Mt.keys && Mt.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function Mi(e) {
  return !!Hr && Hr in e;
}
var Vi = Mi, Zi = Function.prototype, Di = Zi.toString;
function Ui(e) {
  if (e != null) {
    try {
      return Di.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var qn = Ui, qi = Un, Hi = Vi, Bi = Ue, Gi = qn, zi = /[\\^$.*+?()[\]{}|]/g, Ki = /^\[object .+?Constructor\]$/, Fi = Function.prototype, Qi = Object.prototype, Wi = Fi.toString, Ji = Qi.hasOwnProperty, Yi = RegExp(
  "^" + Wi.call(Ji).replace(zi, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Xi(e) {
  if (!Bi(e) || Hi(e))
    return !1;
  var t = qi(e) ? Yi : Ki;
  return t.test(Gi(e));
}
var eo = Xi;
function to(e, t) {
  return e == null ? void 0 : e[t];
}
var ro = to, no = eo, ao = ro;
function so(e, t) {
  var r = ao(e, t);
  return no(r) ? r : void 0;
}
var ue = so, io = ue, oo = G, co = io(oo, "Map"), nr = co, uo = ue, lo = uo(Object, "create"), At = lo, Br = At;
function fo() {
  this.__data__ = Br ? Br(null) : {}, this.size = 0;
}
var po = fo;
function ho(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var vo = ho, yo = At, go = "__lodash_hash_undefined__", mo = Object.prototype, _o = mo.hasOwnProperty;
function bo(e) {
  var t = this.__data__;
  if (yo) {
    var r = t[e];
    return r === go ? void 0 : r;
  }
  return _o.call(t, e) ? t[e] : void 0;
}
var To = bo, $o = At, Ao = Object.prototype, wo = Ao.hasOwnProperty;
function So(e) {
  var t = this.__data__;
  return $o ? t[e] !== void 0 : wo.call(t, e);
}
var xo = So, Eo = At, Oo = "__lodash_hash_undefined__";
function ko(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = Eo && t === void 0 ? Oo : t, this;
}
var jo = ko, Co = po, Ro = vo, Io = To, No = xo, Po = jo;
function Te(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Te.prototype.clear = Co;
Te.prototype.delete = Ro;
Te.prototype.get = Io;
Te.prototype.has = No;
Te.prototype.set = Po;
var Lo = Te, Gr = Lo, Mo = bt, Vo = nr;
function Zo() {
  this.size = 0, this.__data__ = {
    hash: new Gr(),
    map: new (Vo || Mo)(),
    string: new Gr()
  };
}
var Do = Zo;
function Uo(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var qo = Uo, Ho = qo;
function Bo(e, t) {
  var r = e.__data__;
  return Ho(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var wt = Bo, Go = wt;
function zo(e) {
  var t = Go(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var Ko = zo, Fo = wt;
function Qo(e) {
  return Fo(this, e).get(e);
}
var Wo = Qo, Jo = wt;
function Yo(e) {
  return Jo(this, e).has(e);
}
var Xo = Yo, ec = wt;
function tc(e, t) {
  var r = ec(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
var rc = tc, nc = Do, ac = Ko, sc = Wo, ic = Xo, oc = rc;
function $e(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
$e.prototype.clear = nc;
$e.prototype.delete = ac;
$e.prototype.get = sc;
$e.prototype.has = ic;
$e.prototype.set = oc;
var Hn = $e, cc = bt, uc = nr, lc = Hn, dc = 200;
function fc(e, t) {
  var r = this.__data__;
  if (r instanceof cc) {
    var n = r.__data__;
    if (!uc || n.length < dc - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new lc(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
var pc = fc, hc = bt, vc = ei, yc = ri, gc = ai, mc = ii, _c = pc;
function Ae(e) {
  var t = this.__data__ = new hc(e);
  this.size = t.size;
}
Ae.prototype.clear = vc;
Ae.prototype.delete = yc;
Ae.prototype.get = gc;
Ae.prototype.has = mc;
Ae.prototype.set = _c;
var Bn = Ae;
function bc(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n && t(e[r], r, e) !== !1; )
    ;
  return e;
}
var Tc = bc, $c = ue, Ac = function() {
  try {
    var e = $c(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), wc = Ac, zr = wc;
function Sc(e, t, r) {
  t == "__proto__" && zr ? zr(e, t, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : e[t] = r;
}
var Gn = Sc, xc = Gn, Ec = rr, Oc = Object.prototype, kc = Oc.hasOwnProperty;
function jc(e, t, r) {
  var n = e[t];
  (!(kc.call(e, t) && Ec(n, r)) || r === void 0 && !(t in e)) && xc(e, t, r);
}
var zn = jc, Cc = zn, Rc = Gn;
function Ic(e, t, r, n) {
  var a = !r;
  r || (r = {});
  for (var s = -1, o = t.length; ++s < o; ) {
    var c = t[s], l = n ? n(r[c], e[c], c, r, e) : void 0;
    l === void 0 && (l = e[c]), a ? Rc(r, c, l) : Cc(r, c, l);
  }
  return r;
}
var St = Ic;
function Nc(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var Pc = Nc;
function Lc(e) {
  return e != null && typeof e == "object";
}
var we = Lc, Mc = $t, Vc = we, Zc = "[object Arguments]";
function Dc(e) {
  return Vc(e) && Mc(e) == Zc;
}
var Uc = Dc, Kr = Uc, qc = we, Kn = Object.prototype, Hc = Kn.hasOwnProperty, Bc = Kn.propertyIsEnumerable, Gc = Kr(function() {
  return arguments;
}()) ? Kr : function(e) {
  return qc(e) && Hc.call(e, "callee") && !Bc.call(e, "callee");
}, zc = Gc, Kc = Array.isArray, xt = Kc, rt = { exports: {} };
function Fc() {
  return !1;
}
var Qc = Fc;
rt.exports;
(function(e, t) {
  var r = G, n = Qc, a = t && !t.nodeType && t, s = a && !0 && e && !e.nodeType && e, o = s && s.exports === a, c = o ? r.Buffer : void 0, l = c ? c.isBuffer : void 0, u = l || n;
  e.exports = u;
})(rt, rt.exports);
var ar = rt.exports, Wc = 9007199254740991, Jc = /^(?:0|[1-9]\d*)$/;
function Yc(e, t) {
  var r = typeof e;
  return t = t ?? Wc, !!t && (r == "number" || r != "symbol" && Jc.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var Xc = Yc, eu = 9007199254740991;
function tu(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= eu;
}
var Fn = tu, ru = $t, nu = Fn, au = we, su = "[object Arguments]", iu = "[object Array]", ou = "[object Boolean]", cu = "[object Date]", uu = "[object Error]", lu = "[object Function]", du = "[object Map]", fu = "[object Number]", pu = "[object Object]", hu = "[object RegExp]", vu = "[object Set]", yu = "[object String]", gu = "[object WeakMap]", mu = "[object ArrayBuffer]", _u = "[object DataView]", bu = "[object Float32Array]", Tu = "[object Float64Array]", $u = "[object Int8Array]", Au = "[object Int16Array]", wu = "[object Int32Array]", Su = "[object Uint8Array]", xu = "[object Uint8ClampedArray]", Eu = "[object Uint16Array]", Ou = "[object Uint32Array]", S = {};
S[bu] = S[Tu] = S[$u] = S[Au] = S[wu] = S[Su] = S[xu] = S[Eu] = S[Ou] = !0;
S[su] = S[iu] = S[mu] = S[ou] = S[_u] = S[cu] = S[uu] = S[lu] = S[du] = S[fu] = S[pu] = S[hu] = S[vu] = S[yu] = S[gu] = !1;
function ku(e) {
  return au(e) && nu(e.length) && !!S[ru(e)];
}
var ju = ku;
function Cu(e) {
  return function(t) {
    return e(t);
  };
}
var sr = Cu, nt = { exports: {} };
nt.exports;
(function(e, t) {
  var r = Zn, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, s = a && a.exports === n, o = s && r.process, c = function() {
    try {
      var l = a && a.require && a.require("util").types;
      return l || o && o.binding && o.binding("util");
    } catch {
    }
  }();
  e.exports = c;
})(nt, nt.exports);
var ir = nt.exports, Ru = ju, Iu = sr, Fr = ir, Qr = Fr && Fr.isTypedArray, Nu = Qr ? Iu(Qr) : Ru, Qn = Nu, Pu = Pc, Lu = zc, Mu = xt, Vu = ar, Zu = Xc, Du = Qn, Uu = Object.prototype, qu = Uu.hasOwnProperty;
function Hu(e, t) {
  var r = Mu(e), n = !r && Lu(e), a = !r && !n && Vu(e), s = !r && !n && !a && Du(e), o = r || n || a || s, c = o ? Pu(e.length, String) : [], l = c.length;
  for (var u in e)
    (t || qu.call(e, u)) && !(o && // Safari 9 has enumerable `arguments.length` in strict mode.
    (u == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    a && (u == "offset" || u == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    s && (u == "buffer" || u == "byteLength" || u == "byteOffset") || // Skip index properties.
    Zu(u, l))) && c.push(u);
  return c;
}
var Wn = Hu, Bu = Object.prototype;
function Gu(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || Bu;
  return e === r;
}
var or = Gu;
function zu(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var Jn = zu, Ku = Jn, Fu = Ku(Object.keys, Object), Qu = Fu, Wu = or, Ju = Qu, Yu = Object.prototype, Xu = Yu.hasOwnProperty;
function el(e) {
  if (!Wu(e))
    return Ju(e);
  var t = [];
  for (var r in Object(e))
    Xu.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
var tl = el, rl = Un, nl = Fn;
function al(e) {
  return e != null && nl(e.length) && !rl(e);
}
var Yn = al, sl = Wn, il = tl, ol = Yn;
function cl(e) {
  return ol(e) ? sl(e) : il(e);
}
var cr = cl, ul = St, ll = cr;
function dl(e, t) {
  return e && ul(t, ll(t), e);
}
var fl = dl;
function pl(e) {
  var t = [];
  if (e != null)
    for (var r in Object(e))
      t.push(r);
  return t;
}
var hl = pl, vl = Ue, yl = or, gl = hl, ml = Object.prototype, _l = ml.hasOwnProperty;
function bl(e) {
  if (!vl(e))
    return gl(e);
  var t = yl(e), r = [];
  for (var n in e)
    n == "constructor" && (t || !_l.call(e, n)) || r.push(n);
  return r;
}
var Tl = bl, $l = Wn, Al = Tl, wl = Yn;
function Sl(e) {
  return wl(e) ? $l(e, !0) : Al(e);
}
var ur = Sl, xl = St, El = ur;
function Ol(e, t) {
  return e && xl(t, El(t), e);
}
var kl = Ol, at = { exports: {} };
at.exports;
(function(e, t) {
  var r = G, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, s = a && a.exports === n, o = s ? r.Buffer : void 0, c = o ? o.allocUnsafe : void 0;
  function l(u, h) {
    if (h)
      return u.slice();
    var T = u.length, A = c ? c(T) : new u.constructor(T);
    return u.copy(A), A;
  }
  e.exports = l;
})(at, at.exports);
var jl = at.exports;
function Cl(e, t) {
  var r = -1, n = e.length;
  for (t || (t = Array(n)); ++r < n; )
    t[r] = e[r];
  return t;
}
var Rl = Cl;
function Il(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, a = 0, s = []; ++r < n; ) {
    var o = e[r];
    t(o, r, e) && (s[a++] = o);
  }
  return s;
}
var Nl = Il;
function Pl() {
  return [];
}
var Xn = Pl, Ll = Nl, Ml = Xn, Vl = Object.prototype, Zl = Vl.propertyIsEnumerable, Wr = Object.getOwnPropertySymbols, Dl = Wr ? function(e) {
  return e == null ? [] : (e = Object(e), Ll(Wr(e), function(t) {
    return Zl.call(e, t);
  }));
} : Ml, lr = Dl, Ul = St, ql = lr;
function Hl(e, t) {
  return Ul(e, ql(e), t);
}
var Bl = Hl;
function Gl(e, t) {
  for (var r = -1, n = t.length, a = e.length; ++r < n; )
    e[a + r] = t[r];
  return e;
}
var ea = Gl, zl = Jn, Kl = zl(Object.getPrototypeOf, Object), ta = Kl, Fl = ea, Ql = ta, Wl = lr, Jl = Xn, Yl = Object.getOwnPropertySymbols, Xl = Yl ? function(e) {
  for (var t = []; e; )
    Fl(t, Wl(e)), e = Ql(e);
  return t;
} : Jl, ra = Xl, ed = St, td = ra;
function rd(e, t) {
  return ed(e, td(e), t);
}
var nd = rd, ad = ea, sd = xt;
function id(e, t, r) {
  var n = t(e);
  return sd(e) ? n : ad(n, r(e));
}
var na = id, od = na, cd = lr, ud = cr;
function ld(e) {
  return od(e, ud, cd);
}
var aa = ld, dd = na, fd = ra, pd = ur;
function hd(e) {
  return dd(e, pd, fd);
}
var vd = hd, yd = ue, gd = G, md = yd(gd, "DataView"), _d = md, bd = ue, Td = G, $d = bd(Td, "Promise"), Ad = $d, wd = ue, Sd = G, xd = wd(Sd, "Set"), Ed = xd, Od = ue, kd = G, jd = Od(kd, "WeakMap"), Cd = jd, qt = _d, Ht = nr, Bt = Ad, Gt = Ed, zt = Cd, sa = $t, Se = qn, Jr = "[object Map]", Rd = "[object Object]", Yr = "[object Promise]", Xr = "[object Set]", en = "[object WeakMap]", tn = "[object DataView]", Id = Se(qt), Nd = Se(Ht), Pd = Se(Bt), Ld = Se(Gt), Md = Se(zt), ae = sa;
(qt && ae(new qt(new ArrayBuffer(1))) != tn || Ht && ae(new Ht()) != Jr || Bt && ae(Bt.resolve()) != Yr || Gt && ae(new Gt()) != Xr || zt && ae(new zt()) != en) && (ae = function(e) {
  var t = sa(e), r = t == Rd ? e.constructor : void 0, n = r ? Se(r) : "";
  if (n)
    switch (n) {
      case Id:
        return tn;
      case Nd:
        return Jr;
      case Pd:
        return Yr;
      case Ld:
        return Xr;
      case Md:
        return en;
    }
  return t;
});
var Et = ae, Vd = Object.prototype, Zd = Vd.hasOwnProperty;
function Dd(e) {
  var t = e.length, r = new e.constructor(t);
  return t && typeof e[0] == "string" && Zd.call(e, "index") && (r.index = e.index, r.input = e.input), r;
}
var Ud = Dd, qd = G, Hd = qd.Uint8Array, ia = Hd, rn = ia;
function Bd(e) {
  var t = new e.constructor(e.byteLength);
  return new rn(t).set(new rn(e)), t;
}
var dr = Bd, Gd = dr;
function zd(e, t) {
  var r = t ? Gd(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.byteLength);
}
var Kd = zd, Fd = /\w*$/;
function Qd(e) {
  var t = new e.constructor(e.source, Fd.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Wd = Qd, nn = Tt, an = nn ? nn.prototype : void 0, sn = an ? an.valueOf : void 0;
function Jd(e) {
  return sn ? Object(sn.call(e)) : {};
}
var Yd = Jd, Xd = dr;
function ef(e, t) {
  var r = t ? Xd(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.length);
}
var tf = ef, rf = dr, nf = Kd, af = Wd, sf = Yd, of = tf, cf = "[object Boolean]", uf = "[object Date]", lf = "[object Map]", df = "[object Number]", ff = "[object RegExp]", pf = "[object Set]", hf = "[object String]", vf = "[object Symbol]", yf = "[object ArrayBuffer]", gf = "[object DataView]", mf = "[object Float32Array]", _f = "[object Float64Array]", bf = "[object Int8Array]", Tf = "[object Int16Array]", $f = "[object Int32Array]", Af = "[object Uint8Array]", wf = "[object Uint8ClampedArray]", Sf = "[object Uint16Array]", xf = "[object Uint32Array]";
function Ef(e, t, r) {
  var n = e.constructor;
  switch (t) {
    case yf:
      return rf(e);
    case cf:
    case uf:
      return new n(+e);
    case gf:
      return nf(e, r);
    case mf:
    case _f:
    case bf:
    case Tf:
    case $f:
    case Af:
    case wf:
    case Sf:
    case xf:
      return of(e, r);
    case lf:
      return new n();
    case df:
    case hf:
      return new n(e);
    case ff:
      return af(e);
    case pf:
      return new n();
    case vf:
      return sf(e);
  }
}
var Of = Ef, kf = Ue, on = Object.create, jf = function() {
  function e() {
  }
  return function(t) {
    if (!kf(t))
      return {};
    if (on)
      return on(t);
    e.prototype = t;
    var r = new e();
    return e.prototype = void 0, r;
  };
}(), Cf = jf, Rf = Cf, If = ta, Nf = or;
function Pf(e) {
  return typeof e.constructor == "function" && !Nf(e) ? Rf(If(e)) : {};
}
var Lf = Pf, Mf = Et, Vf = we, Zf = "[object Map]";
function Df(e) {
  return Vf(e) && Mf(e) == Zf;
}
var Uf = Df, qf = Uf, Hf = sr, cn = ir, un = cn && cn.isMap, Bf = un ? Hf(un) : qf, Gf = Bf, zf = Et, Kf = we, Ff = "[object Set]";
function Qf(e) {
  return Kf(e) && zf(e) == Ff;
}
var Wf = Qf, Jf = Wf, Yf = sr, ln = ir, dn = ln && ln.isSet, Xf = dn ? Yf(dn) : Jf, ep = Xf, tp = Bn, rp = Tc, np = zn, ap = fl, sp = kl, ip = jl, op = Rl, cp = Bl, up = nd, lp = aa, dp = vd, fp = Et, pp = Ud, hp = Of, vp = Lf, yp = xt, gp = ar, mp = Gf, _p = Ue, bp = ep, Tp = cr, $p = ur, Ap = 1, wp = 2, Sp = 4, oa = "[object Arguments]", xp = "[object Array]", Ep = "[object Boolean]", Op = "[object Date]", kp = "[object Error]", ca = "[object Function]", jp = "[object GeneratorFunction]", Cp = "[object Map]", Rp = "[object Number]", ua = "[object Object]", Ip = "[object RegExp]", Np = "[object Set]", Pp = "[object String]", Lp = "[object Symbol]", Mp = "[object WeakMap]", Vp = "[object ArrayBuffer]", Zp = "[object DataView]", Dp = "[object Float32Array]", Up = "[object Float64Array]", qp = "[object Int8Array]", Hp = "[object Int16Array]", Bp = "[object Int32Array]", Gp = "[object Uint8Array]", zp = "[object Uint8ClampedArray]", Kp = "[object Uint16Array]", Fp = "[object Uint32Array]", w = {};
w[oa] = w[xp] = w[Vp] = w[Zp] = w[Ep] = w[Op] = w[Dp] = w[Up] = w[qp] = w[Hp] = w[Bp] = w[Cp] = w[Rp] = w[ua] = w[Ip] = w[Np] = w[Pp] = w[Lp] = w[Gp] = w[zp] = w[Kp] = w[Fp] = !0;
w[kp] = w[ca] = w[Mp] = !1;
function et(e, t, r, n, a, s) {
  var o, c = t & Ap, l = t & wp, u = t & Sp;
  if (r && (o = a ? r(e, n, a, s) : r(e)), o !== void 0)
    return o;
  if (!_p(e))
    return e;
  var h = yp(e);
  if (h) {
    if (o = pp(e), !c)
      return op(e, o);
  } else {
    var T = fp(e), A = T == ca || T == jp;
    if (gp(e))
      return ip(e, c);
    if (T == ua || T == oa || A && !a) {
      if (o = l || A ? {} : vp(e), !c)
        return l ? up(e, sp(o, e)) : cp(e, ap(o, e));
    } else {
      if (!w[T])
        return a ? e : {};
      o = hp(e, T, c);
    }
  }
  s || (s = new tp());
  var C = s.get(e);
  if (C)
    return C;
  s.set(e, o), bp(e) ? e.forEach(function(j) {
    o.add(et(j, t, r, j, e, s));
  }) : mp(e) && e.forEach(function(j, I) {
    o.set(I, et(j, t, r, I, e, s));
  });
  var P = u ? l ? dp : lp : l ? $p : Tp, L = h ? void 0 : P(e);
  return rp(L || e, function(j, I) {
    L && (I = j, j = e[I]), np(o, I, et(j, t, r, I, e, s));
  }), o;
}
var Qp = et, Wp = Qp, Jp = 1, Yp = 4;
function Xp(e) {
  return Wp(e, Jp | Yp);
}
var eh = Xp;
const th = /* @__PURE__ */ Vn(eh);
var $;
(function(e) {
  e.assertEqual = (a) => a;
  function t(a) {
  }
  e.assertIs = t;
  function r(a) {
    throw new Error();
  }
  e.assertNever = r, e.arrayToEnum = (a) => {
    const s = {};
    for (const o of a)
      s[o] = o;
    return s;
  }, e.getValidEnumValues = (a) => {
    const s = e.objectKeys(a).filter((c) => typeof a[a[c]] != "number"), o = {};
    for (const c of s)
      o[c] = a[c];
    return e.objectValues(o);
  }, e.objectValues = (a) => e.objectKeys(a).map(function(s) {
    return a[s];
  }), e.objectKeys = typeof Object.keys == "function" ? (a) => Object.keys(a) : (a) => {
    const s = [];
    for (const o in a)
      Object.prototype.hasOwnProperty.call(a, o) && s.push(o);
    return s;
  }, e.find = (a, s) => {
    for (const o of a)
      if (s(o))
        return o;
  }, e.isInteger = typeof Number.isInteger == "function" ? (a) => Number.isInteger(a) : (a) => typeof a == "number" && isFinite(a) && Math.floor(a) === a;
  function n(a, s = " | ") {
    return a.map((o) => typeof o == "string" ? `'${o}'` : o).join(s);
  }
  e.joinValues = n, e.jsonStringifyReplacer = (a, s) => typeof s == "bigint" ? s.toString() : s;
})($ || ($ = {}));
var Kt;
(function(e) {
  e.mergeShapes = (t, r) => ({
    ...t,
    ...r
    // second overwrites first
  });
})(Kt || (Kt = {}));
const f = $.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]), Q = (e) => {
  switch (typeof e) {
    case "undefined":
      return f.undefined;
    case "string":
      return f.string;
    case "number":
      return isNaN(e) ? f.nan : f.number;
    case "boolean":
      return f.boolean;
    case "function":
      return f.function;
    case "bigint":
      return f.bigint;
    case "symbol":
      return f.symbol;
    case "object":
      return Array.isArray(e) ? f.array : e === null ? f.null : e.then && typeof e.then == "function" && e.catch && typeof e.catch == "function" ? f.promise : typeof Map < "u" && e instanceof Map ? f.map : typeof Set < "u" && e instanceof Set ? f.set : typeof Date < "u" && e instanceof Date ? f.date : f.object;
    default:
      return f.unknown;
  }
}, d = $.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]), rh = (e) => JSON.stringify(e, null, 2).replace(/"([^"]+)":/g, "$1:");
class Z extends Error {
  constructor(t) {
    super(), this.issues = [], this.addIssue = (n) => {
      this.issues = [...this.issues, n];
    }, this.addIssues = (n = []) => {
      this.issues = [...this.issues, ...n];
    };
    const r = new.target.prototype;
    Object.setPrototypeOf ? Object.setPrototypeOf(this, r) : this.__proto__ = r, this.name = "ZodError", this.issues = t;
  }
  get errors() {
    return this.issues;
  }
  format(t) {
    const r = t || function(s) {
      return s.message;
    }, n = { _errors: [] }, a = (s) => {
      for (const o of s.issues)
        if (o.code === "invalid_union")
          o.unionErrors.map(a);
        else if (o.code === "invalid_return_type")
          a(o.returnTypeError);
        else if (o.code === "invalid_arguments")
          a(o.argumentsError);
        else if (o.path.length === 0)
          n._errors.push(r(o));
        else {
          let c = n, l = 0;
          for (; l < o.path.length; ) {
            const u = o.path[l];
            l === o.path.length - 1 ? (c[u] = c[u] || { _errors: [] }, c[u]._errors.push(r(o))) : c[u] = c[u] || { _errors: [] }, c = c[u], l++;
          }
        }
    };
    return a(this), n;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, $.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(t = (r) => r.message) {
    const r = {}, n = [];
    for (const a of this.issues)
      a.path.length > 0 ? (r[a.path[0]] = r[a.path[0]] || [], r[a.path[0]].push(t(a))) : n.push(t(a));
    return { formErrors: n, fieldErrors: r };
  }
  get formErrors() {
    return this.flatten();
  }
}
Z.create = (e) => new Z(e);
const Oe = (e, t) => {
  let r;
  switch (e.code) {
    case d.invalid_type:
      e.received === f.undefined ? r = "Required" : r = `Expected ${e.expected}, received ${e.received}`;
      break;
    case d.invalid_literal:
      r = `Invalid literal value, expected ${JSON.stringify(e.expected, $.jsonStringifyReplacer)}`;
      break;
    case d.unrecognized_keys:
      r = `Unrecognized key(s) in object: ${$.joinValues(e.keys, ", ")}`;
      break;
    case d.invalid_union:
      r = "Invalid input";
      break;
    case d.invalid_union_discriminator:
      r = `Invalid discriminator value. Expected ${$.joinValues(e.options)}`;
      break;
    case d.invalid_enum_value:
      r = `Invalid enum value. Expected ${$.joinValues(e.options)}, received '${e.received}'`;
      break;
    case d.invalid_arguments:
      r = "Invalid function arguments";
      break;
    case d.invalid_return_type:
      r = "Invalid function return type";
      break;
    case d.invalid_date:
      r = "Invalid date";
      break;
    case d.invalid_string:
      typeof e.validation == "object" ? "includes" in e.validation ? (r = `Invalid input: must include "${e.validation.includes}"`, typeof e.validation.position == "number" && (r = `${r} at one or more positions greater than or equal to ${e.validation.position}`)) : "startsWith" in e.validation ? r = `Invalid input: must start with "${e.validation.startsWith}"` : "endsWith" in e.validation ? r = `Invalid input: must end with "${e.validation.endsWith}"` : $.assertNever(e.validation) : e.validation !== "regex" ? r = `Invalid ${e.validation}` : r = "Invalid";
      break;
    case d.too_small:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "more than"} ${e.minimum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "over"} ${e.minimum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${e.minimum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${new Date(Number(e.minimum))}` : r = "Invalid input";
      break;
    case d.too_big:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "less than"} ${e.maximum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "under"} ${e.maximum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "bigint" ? r = `BigInt must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly" : e.inclusive ? "smaller than or equal to" : "smaller than"} ${new Date(Number(e.maximum))}` : r = "Invalid input";
      break;
    case d.custom:
      r = "Invalid input";
      break;
    case d.invalid_intersection_types:
      r = "Intersection results could not be merged";
      break;
    case d.not_multiple_of:
      r = `Number must be a multiple of ${e.multipleOf}`;
      break;
    case d.not_finite:
      r = "Number must be finite";
      break;
    default:
      r = t.defaultError, $.assertNever(e);
  }
  return { message: r };
};
let la = Oe;
function nh(e) {
  la = e;
}
function st() {
  return la;
}
const it = (e) => {
  const { data: t, path: r, errorMaps: n, issueData: a } = e, s = [...r, ...a.path || []], o = {
    ...a,
    path: s
  };
  let c = "";
  const l = n.filter((u) => !!u).slice().reverse();
  for (const u of l)
    c = u(o, { data: t, defaultError: c }).message;
  return {
    ...a,
    path: s,
    message: a.message || c
  };
}, ah = [];
function p(e, t) {
  const r = it({
    issueData: t,
    data: e.data,
    path: e.path,
    errorMaps: [
      e.common.contextualErrorMap,
      e.schemaErrorMap,
      st(),
      Oe
      // then global default map
    ].filter((n) => !!n)
  });
  e.common.issues.push(r);
}
class R {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    this.value === "valid" && (this.value = "dirty");
  }
  abort() {
    this.value !== "aborted" && (this.value = "aborted");
  }
  static mergeArray(t, r) {
    const n = [];
    for (const a of r) {
      if (a.status === "aborted")
        return m;
      a.status === "dirty" && t.dirty(), n.push(a.value);
    }
    return { status: t.value, value: n };
  }
  static async mergeObjectAsync(t, r) {
    const n = [];
    for (const a of r)
      n.push({
        key: await a.key,
        value: await a.value
      });
    return R.mergeObjectSync(t, n);
  }
  static mergeObjectSync(t, r) {
    const n = {};
    for (const a of r) {
      const { key: s, value: o } = a;
      if (s.status === "aborted" || o.status === "aborted")
        return m;
      s.status === "dirty" && t.dirty(), o.status === "dirty" && t.dirty(), s.value !== "__proto__" && (typeof o.value < "u" || a.alwaysSet) && (n[s.value] = o.value);
    }
    return { status: t.value, value: n };
  }
}
const m = Object.freeze({
  status: "aborted"
}), da = (e) => ({ status: "dirty", value: e }), N = (e) => ({ status: "valid", value: e }), Ft = (e) => e.status === "aborted", Qt = (e) => e.status === "dirty", ke = (e) => e.status === "valid", ot = (e) => typeof Promise < "u" && e instanceof Promise;
var v;
(function(e) {
  e.errToObj = (t) => typeof t == "string" ? { message: t } : t || {}, e.toString = (t) => typeof t == "string" ? t : t == null ? void 0 : t.message;
})(v || (v = {}));
class q {
  constructor(t, r, n, a) {
    this._cachedPath = [], this.parent = t, this.data = r, this._path = n, this._key = a;
  }
  get path() {
    return this._cachedPath.length || (this._key instanceof Array ? this._cachedPath.push(...this._path, ...this._key) : this._cachedPath.push(...this._path, this._key)), this._cachedPath;
  }
}
const fn = (e, t) => {
  if (ke(t))
    return { success: !0, data: t.value };
  if (!e.common.issues.length)
    throw new Error("Validation failed but no issues detected.");
  return {
    success: !1,
    get error() {
      if (this._error)
        return this._error;
      const r = new Z(e.common.issues);
      return this._error = r, this._error;
    }
  };
};
function _(e) {
  if (!e)
    return {};
  const { errorMap: t, invalid_type_error: r, required_error: n, description: a } = e;
  if (t && (r || n))
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  return t ? { errorMap: t, description: a } : { errorMap: (o, c) => o.code !== "invalid_type" ? { message: c.defaultError } : typeof c.data > "u" ? { message: n ?? c.defaultError } : { message: r ?? c.defaultError }, description: a };
}
class b {
  constructor(t) {
    this.spa = this.safeParseAsync, this._def = t, this.parse = this.parse.bind(this), this.safeParse = this.safeParse.bind(this), this.parseAsync = this.parseAsync.bind(this), this.safeParseAsync = this.safeParseAsync.bind(this), this.spa = this.spa.bind(this), this.refine = this.refine.bind(this), this.refinement = this.refinement.bind(this), this.superRefine = this.superRefine.bind(this), this.optional = this.optional.bind(this), this.nullable = this.nullable.bind(this), this.nullish = this.nullish.bind(this), this.array = this.array.bind(this), this.promise = this.promise.bind(this), this.or = this.or.bind(this), this.and = this.and.bind(this), this.transform = this.transform.bind(this), this.brand = this.brand.bind(this), this.default = this.default.bind(this), this.catch = this.catch.bind(this), this.describe = this.describe.bind(this), this.pipe = this.pipe.bind(this), this.readonly = this.readonly.bind(this), this.isNullable = this.isNullable.bind(this), this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(t) {
    return Q(t.data);
  }
  _getOrReturnCtx(t, r) {
    return r || {
      common: t.parent.common,
      data: t.data,
      parsedType: Q(t.data),
      schemaErrorMap: this._def.errorMap,
      path: t.path,
      parent: t.parent
    };
  }
  _processInputParams(t) {
    return {
      status: new R(),
      ctx: {
        common: t.parent.common,
        data: t.data,
        parsedType: Q(t.data),
        schemaErrorMap: this._def.errorMap,
        path: t.path,
        parent: t.parent
      }
    };
  }
  _parseSync(t) {
    const r = this._parse(t);
    if (ot(r))
      throw new Error("Synchronous parse encountered promise.");
    return r;
  }
  _parseAsync(t) {
    const r = this._parse(t);
    return Promise.resolve(r);
  }
  parse(t, r) {
    const n = this.safeParse(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  safeParse(t, r) {
    var n;
    const a = {
      common: {
        issues: [],
        async: (n = r == null ? void 0 : r.async) !== null && n !== void 0 ? n : !1,
        contextualErrorMap: r == null ? void 0 : r.errorMap
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: Q(t)
    }, s = this._parseSync({ data: t, path: a.path, parent: a });
    return fn(a, s);
  }
  async parseAsync(t, r) {
    const n = await this.safeParseAsync(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  async safeParseAsync(t, r) {
    const n = {
      common: {
        issues: [],
        contextualErrorMap: r == null ? void 0 : r.errorMap,
        async: !0
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: Q(t)
    }, a = this._parse({ data: t, path: n.path, parent: n }), s = await (ot(a) ? a : Promise.resolve(a));
    return fn(n, s);
  }
  refine(t, r) {
    const n = (a) => typeof r == "string" || typeof r > "u" ? { message: r } : typeof r == "function" ? r(a) : r;
    return this._refinement((a, s) => {
      const o = t(a), c = () => s.addIssue({
        code: d.custom,
        ...n(a)
      });
      return typeof Promise < "u" && o instanceof Promise ? o.then((l) => l ? !0 : (c(), !1)) : o ? !0 : (c(), !1);
    });
  }
  refinement(t, r) {
    return this._refinement((n, a) => t(n) ? !0 : (a.addIssue(typeof r == "function" ? r(n, a) : r), !1));
  }
  _refinement(t) {
    return new U({
      schema: this,
      typeName: y.ZodEffects,
      effect: { type: "refinement", refinement: t }
    });
  }
  superRefine(t) {
    return this._refinement(t);
  }
  optional() {
    return z.create(this, this._def);
  }
  nullable() {
    return ce.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return D.create(this, this._def);
  }
  promise() {
    return ve.create(this, this._def);
  }
  or(t) {
    return Ie.create([this, t], this._def);
  }
  and(t) {
    return Ne.create(this, t, this._def);
  }
  transform(t) {
    return new U({
      ..._(this._def),
      schema: this,
      typeName: y.ZodEffects,
      effect: { type: "transform", transform: t }
    });
  }
  default(t) {
    const r = typeof t == "function" ? t : () => t;
    return new Ze({
      ..._(this._def),
      innerType: this,
      defaultValue: r,
      typeName: y.ZodDefault
    });
  }
  brand() {
    return new pa({
      typeName: y.ZodBranded,
      type: this,
      ..._(this._def)
    });
  }
  catch(t) {
    const r = typeof t == "function" ? t : () => t;
    return new dt({
      ..._(this._def),
      innerType: this,
      catchValue: r,
      typeName: y.ZodCatch
    });
  }
  describe(t) {
    const r = this.constructor;
    return new r({
      ...this._def,
      description: t
    });
  }
  pipe(t) {
    return qe.create(this, t);
  }
  readonly() {
    return pt.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const sh = /^c[^\s-]{8,}$/i, ih = /^[a-z][a-z0-9]*$/, oh = /^[0-9A-HJKMNP-TV-Z]{26}$/, ch = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i, uh = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i, lh = "^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$";
let Vt;
const dh = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/, fh = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/, ph = (e) => e.precision ? e.offset ? new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`) : new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}Z$`) : e.precision === 0 ? e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$") : e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$");
function hh(e, t) {
  return !!((t === "v4" || !t) && dh.test(e) || (t === "v6" || !t) && fh.test(e));
}
class V extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = String(t.data)), this._getType(t) !== f.string) {
      const s = this._getOrReturnCtx(t);
      return p(
        s,
        {
          code: d.invalid_type,
          expected: f.string,
          received: s.parsedType
        }
        //
      ), m;
    }
    const n = new R();
    let a;
    for (const s of this._def.checks)
      if (s.kind === "min")
        t.data.length < s.value && (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), n.dirty());
      else if (s.kind === "max")
        t.data.length > s.value && (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), n.dirty());
      else if (s.kind === "length") {
        const o = t.data.length > s.value, c = t.data.length < s.value;
        (o || c) && (a = this._getOrReturnCtx(t, a), o ? p(a, {
          code: d.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }) : c && p(a, {
          code: d.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }), n.dirty());
      } else if (s.kind === "email")
        uh.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "email",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "emoji")
        Vt || (Vt = new RegExp(lh, "u")), Vt.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "emoji",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "uuid")
        ch.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "uuid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "cuid")
        sh.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "cuid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "cuid2")
        ih.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "cuid2",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "ulid")
        oh.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "ulid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "url")
        try {
          new URL(t.data);
        } catch {
          a = this._getOrReturnCtx(t, a), p(a, {
            validation: "url",
            code: d.invalid_string,
            message: s.message
          }), n.dirty();
        }
      else
        s.kind === "regex" ? (s.regex.lastIndex = 0, s.regex.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "regex",
          code: d.invalid_string,
          message: s.message
        }), n.dirty())) : s.kind === "trim" ? t.data = t.data.trim() : s.kind === "includes" ? t.data.includes(s.value, s.position) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { includes: s.value, position: s.position },
          message: s.message
        }), n.dirty()) : s.kind === "toLowerCase" ? t.data = t.data.toLowerCase() : s.kind === "toUpperCase" ? t.data = t.data.toUpperCase() : s.kind === "startsWith" ? t.data.startsWith(s.value) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { startsWith: s.value },
          message: s.message
        }), n.dirty()) : s.kind === "endsWith" ? t.data.endsWith(s.value) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { endsWith: s.value },
          message: s.message
        }), n.dirty()) : s.kind === "datetime" ? ph(s).test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: "datetime",
          message: s.message
        }), n.dirty()) : s.kind === "ip" ? hh(t.data, s.version) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "ip",
          code: d.invalid_string,
          message: s.message
        }), n.dirty()) : $.assertNever(s);
    return { status: n.value, value: t.data };
  }
  _regex(t, r, n) {
    return this.refinement((a) => t.test(a), {
      validation: r,
      code: d.invalid_string,
      ...v.errToObj(n)
    });
  }
  _addCheck(t) {
    return new V({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  email(t) {
    return this._addCheck({ kind: "email", ...v.errToObj(t) });
  }
  url(t) {
    return this._addCheck({ kind: "url", ...v.errToObj(t) });
  }
  emoji(t) {
    return this._addCheck({ kind: "emoji", ...v.errToObj(t) });
  }
  uuid(t) {
    return this._addCheck({ kind: "uuid", ...v.errToObj(t) });
  }
  cuid(t) {
    return this._addCheck({ kind: "cuid", ...v.errToObj(t) });
  }
  cuid2(t) {
    return this._addCheck({ kind: "cuid2", ...v.errToObj(t) });
  }
  ulid(t) {
    return this._addCheck({ kind: "ulid", ...v.errToObj(t) });
  }
  ip(t) {
    return this._addCheck({ kind: "ip", ...v.errToObj(t) });
  }
  datetime(t) {
    var r;
    return typeof t == "string" ? this._addCheck({
      kind: "datetime",
      precision: null,
      offset: !1,
      message: t
    }) : this._addCheck({
      kind: "datetime",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      offset: (r = t == null ? void 0 : t.offset) !== null && r !== void 0 ? r : !1,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  regex(t, r) {
    return this._addCheck({
      kind: "regex",
      regex: t,
      ...v.errToObj(r)
    });
  }
  includes(t, r) {
    return this._addCheck({
      kind: "includes",
      value: t,
      position: r == null ? void 0 : r.position,
      ...v.errToObj(r == null ? void 0 : r.message)
    });
  }
  startsWith(t, r) {
    return this._addCheck({
      kind: "startsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  endsWith(t, r) {
    return this._addCheck({
      kind: "endsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t,
      ...v.errToObj(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t,
      ...v.errToObj(r)
    });
  }
  length(t, r) {
    return this._addCheck({
      kind: "length",
      value: t,
      ...v.errToObj(r)
    });
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(t) {
    return this.min(1, v.errToObj(t));
  }
  trim() {
    return new V({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
  }
  toLowerCase() {
    return new V({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
  }
  toUpperCase() {
    return new V({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((t) => t.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((t) => t.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((t) => t.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((t) => t.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((t) => t.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((t) => t.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((t) => t.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((t) => t.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((t) => t.kind === "ip");
  }
  get minLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
V.create = (e) => {
  var t;
  return new V({
    checks: [],
    typeName: y.ZodString,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ..._(e)
  });
};
function vh(e, t) {
  const r = (e.toString().split(".")[1] || "").length, n = (t.toString().split(".")[1] || "").length, a = r > n ? r : n, s = parseInt(e.toFixed(a).replace(".", "")), o = parseInt(t.toFixed(a).replace(".", ""));
  return s % o / Math.pow(10, a);
}
class J extends b {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte, this.step = this.multipleOf;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = Number(t.data)), this._getType(t) !== f.number) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.number,
        received: s.parsedType
      }), m;
    }
    let n;
    const a = new R();
    for (const s of this._def.checks)
      s.kind === "int" ? $.isInteger(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.invalid_type,
        expected: "integer",
        received: "float",
        message: s.message
      }), a.dirty()) : s.kind === "min" ? (s.inclusive ? t.data < s.value : t.data <= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        minimum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), a.dirty()) : s.kind === "max" ? (s.inclusive ? t.data > s.value : t.data >= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        maximum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), a.dirty()) : s.kind === "multipleOf" ? vh(t.data, s.value) !== 0 && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), a.dirty()) : s.kind === "finite" ? Number.isFinite(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_finite,
        message: s.message
      }), a.dirty()) : $.assertNever(s);
    return { status: a.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, a) {
    return new J({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(a)
        }
      ]
    });
  }
  _addCheck(t) {
    return new J({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  int(t) {
    return this._addCheck({
      kind: "int",
      message: v.toString(t)
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  finite(t) {
    return this._addCheck({
      kind: "finite",
      message: v.toString(t)
    });
  }
  safe(t) {
    return this._addCheck({
      kind: "min",
      inclusive: !0,
      value: Number.MIN_SAFE_INTEGER,
      message: v.toString(t)
    })._addCheck({
      kind: "max",
      inclusive: !0,
      value: Number.MAX_SAFE_INTEGER,
      message: v.toString(t)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
  get isInt() {
    return !!this._def.checks.find((t) => t.kind === "int" || t.kind === "multipleOf" && $.isInteger(t.value));
  }
  get isFinite() {
    let t = null, r = null;
    for (const n of this._def.checks) {
      if (n.kind === "finite" || n.kind === "int" || n.kind === "multipleOf")
        return !0;
      n.kind === "min" ? (r === null || n.value > r) && (r = n.value) : n.kind === "max" && (t === null || n.value < t) && (t = n.value);
    }
    return Number.isFinite(r) && Number.isFinite(t);
  }
}
J.create = (e) => new J({
  checks: [],
  typeName: y.ZodNumber,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ..._(e)
});
class Y extends b {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = BigInt(t.data)), this._getType(t) !== f.bigint) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.bigint,
        received: s.parsedType
      }), m;
    }
    let n;
    const a = new R();
    for (const s of this._def.checks)
      s.kind === "min" ? (s.inclusive ? t.data < s.value : t.data <= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        type: "bigint",
        minimum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), a.dirty()) : s.kind === "max" ? (s.inclusive ? t.data > s.value : t.data >= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        type: "bigint",
        maximum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), a.dirty()) : s.kind === "multipleOf" ? t.data % s.value !== BigInt(0) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), a.dirty()) : $.assertNever(s);
    return { status: a.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, a) {
    return new Y({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(a)
        }
      ]
    });
  }
  _addCheck(t) {
    return new Y({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
Y.create = (e) => {
  var t;
  return new Y({
    checks: [],
    typeName: y.ZodBigInt,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ..._(e)
  });
};
class je extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = !!t.data), this._getType(t) !== f.boolean) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.boolean,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
je.create = (e) => new je({
  typeName: y.ZodBoolean,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ..._(e)
});
class ie extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = new Date(t.data)), this._getType(t) !== f.date) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.date,
        received: s.parsedType
      }), m;
    }
    if (isNaN(t.data.getTime())) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_date
      }), m;
    }
    const n = new R();
    let a;
    for (const s of this._def.checks)
      s.kind === "min" ? t.data.getTime() < s.value && (a = this._getOrReturnCtx(t, a), p(a, {
        code: d.too_small,
        message: s.message,
        inclusive: !0,
        exact: !1,
        minimum: s.value,
        type: "date"
      }), n.dirty()) : s.kind === "max" ? t.data.getTime() > s.value && (a = this._getOrReturnCtx(t, a), p(a, {
        code: d.too_big,
        message: s.message,
        inclusive: !0,
        exact: !1,
        maximum: s.value,
        type: "date"
      }), n.dirty()) : $.assertNever(s);
    return {
      status: n.value,
      value: new Date(t.data.getTime())
    };
  }
  _addCheck(t) {
    return new ie({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  get minDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
  get maxDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
}
ie.create = (e) => new ie({
  checks: [],
  coerce: (e == null ? void 0 : e.coerce) || !1,
  typeName: y.ZodDate,
  ..._(e)
});
class ct extends b {
  _parse(t) {
    if (this._getType(t) !== f.symbol) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.symbol,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
ct.create = (e) => new ct({
  typeName: y.ZodSymbol,
  ..._(e)
});
class Ce extends b {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.undefined,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
Ce.create = (e) => new Ce({
  typeName: y.ZodUndefined,
  ..._(e)
});
class Re extends b {
  _parse(t) {
    if (this._getType(t) !== f.null) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.null,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
Re.create = (e) => new Re({
  typeName: y.ZodNull,
  ..._(e)
});
class he extends b {
  constructor() {
    super(...arguments), this._any = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
he.create = (e) => new he({
  typeName: y.ZodAny,
  ..._(e)
});
class se extends b {
  constructor() {
    super(...arguments), this._unknown = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
se.create = (e) => new se({
  typeName: y.ZodUnknown,
  ..._(e)
});
class K extends b {
  _parse(t) {
    const r = this._getOrReturnCtx(t);
    return p(r, {
      code: d.invalid_type,
      expected: f.never,
      received: r.parsedType
    }), m;
  }
}
K.create = (e) => new K({
  typeName: y.ZodNever,
  ..._(e)
});
class ut extends b {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.void,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
ut.create = (e) => new ut({
  typeName: y.ZodVoid,
  ..._(e)
});
class D extends b {
  _parse(t) {
    const { ctx: r, status: n } = this._processInputParams(t), a = this._def;
    if (r.parsedType !== f.array)
      return p(r, {
        code: d.invalid_type,
        expected: f.array,
        received: r.parsedType
      }), m;
    if (a.exactLength !== null) {
      const o = r.data.length > a.exactLength.value, c = r.data.length < a.exactLength.value;
      (o || c) && (p(r, {
        code: o ? d.too_big : d.too_small,
        minimum: c ? a.exactLength.value : void 0,
        maximum: o ? a.exactLength.value : void 0,
        type: "array",
        inclusive: !0,
        exact: !0,
        message: a.exactLength.message
      }), n.dirty());
    }
    if (a.minLength !== null && r.data.length < a.minLength.value && (p(r, {
      code: d.too_small,
      minimum: a.minLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: a.minLength.message
    }), n.dirty()), a.maxLength !== null && r.data.length > a.maxLength.value && (p(r, {
      code: d.too_big,
      maximum: a.maxLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: a.maxLength.message
    }), n.dirty()), r.common.async)
      return Promise.all([...r.data].map((o, c) => a.type._parseAsync(new q(r, o, r.path, c)))).then((o) => R.mergeArray(n, o));
    const s = [...r.data].map((o, c) => a.type._parseSync(new q(r, o, r.path, c)));
    return R.mergeArray(n, s);
  }
  get element() {
    return this._def.type;
  }
  min(t, r) {
    return new D({
      ...this._def,
      minLength: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new D({
      ...this._def,
      maxLength: { value: t, message: v.toString(r) }
    });
  }
  length(t, r) {
    return new D({
      ...this._def,
      exactLength: { value: t, message: v.toString(r) }
    });
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
D.create = (e, t) => new D({
  type: e,
  minLength: null,
  maxLength: null,
  exactLength: null,
  typeName: y.ZodArray,
  ..._(t)
});
function fe(e) {
  if (e instanceof x) {
    const t = {};
    for (const r in e.shape) {
      const n = e.shape[r];
      t[r] = z.create(fe(n));
    }
    return new x({
      ...e._def,
      shape: () => t
    });
  } else
    return e instanceof D ? new D({
      ...e._def,
      type: fe(e.element)
    }) : e instanceof z ? z.create(fe(e.unwrap())) : e instanceof ce ? ce.create(fe(e.unwrap())) : e instanceof H ? H.create(e.items.map((t) => fe(t))) : e;
}
class x extends b {
  constructor() {
    super(...arguments), this._cached = null, this.nonstrict = this.passthrough, this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const t = this._def.shape(), r = $.objectKeys(t);
    return this._cached = { shape: t, keys: r };
  }
  _parse(t) {
    if (this._getType(t) !== f.object) {
      const u = this._getOrReturnCtx(t);
      return p(u, {
        code: d.invalid_type,
        expected: f.object,
        received: u.parsedType
      }), m;
    }
    const { status: n, ctx: a } = this._processInputParams(t), { shape: s, keys: o } = this._getCached(), c = [];
    if (!(this._def.catchall instanceof K && this._def.unknownKeys === "strip"))
      for (const u in a.data)
        o.includes(u) || c.push(u);
    const l = [];
    for (const u of o) {
      const h = s[u], T = a.data[u];
      l.push({
        key: { status: "valid", value: u },
        value: h._parse(new q(a, T, a.path, u)),
        alwaysSet: u in a.data
      });
    }
    if (this._def.catchall instanceof K) {
      const u = this._def.unknownKeys;
      if (u === "passthrough")
        for (const h of c)
          l.push({
            key: { status: "valid", value: h },
            value: { status: "valid", value: a.data[h] }
          });
      else if (u === "strict")
        c.length > 0 && (p(a, {
          code: d.unrecognized_keys,
          keys: c
        }), n.dirty());
      else if (u !== "strip")
        throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      const u = this._def.catchall;
      for (const h of c) {
        const T = a.data[h];
        l.push({
          key: { status: "valid", value: h },
          value: u._parse(
            new q(a, T, a.path, h)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: h in a.data
        });
      }
    }
    return a.common.async ? Promise.resolve().then(async () => {
      const u = [];
      for (const h of l) {
        const T = await h.key;
        u.push({
          key: T,
          value: await h.value,
          alwaysSet: h.alwaysSet
        });
      }
      return u;
    }).then((u) => R.mergeObjectSync(n, u)) : R.mergeObjectSync(n, l);
  }
  get shape() {
    return this._def.shape();
  }
  strict(t) {
    return v.errToObj, new x({
      ...this._def,
      unknownKeys: "strict",
      ...t !== void 0 ? {
        errorMap: (r, n) => {
          var a, s, o, c;
          const l = (o = (s = (a = this._def).errorMap) === null || s === void 0 ? void 0 : s.call(a, r, n).message) !== null && o !== void 0 ? o : n.defaultError;
          return r.code === "unrecognized_keys" ? {
            message: (c = v.errToObj(t).message) !== null && c !== void 0 ? c : l
          } : {
            message: l
          };
        }
      } : {}
    });
  }
  strip() {
    return new x({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new x({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(t) {
    return new x({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...t
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(t) {
    return new x({
      unknownKeys: t._def.unknownKeys,
      catchall: t._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...t._def.shape()
      }),
      typeName: y.ZodObject
    });
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(t, r) {
    return this.augment({ [t]: r });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(t) {
    return new x({
      ...this._def,
      catchall: t
    });
  }
  pick(t) {
    const r = {};
    return $.objectKeys(t).forEach((n) => {
      t[n] && this.shape[n] && (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  omit(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      t[n] || (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return fe(this);
  }
  partial(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      const a = this.shape[n];
      t && !t[n] ? r[n] = a : r[n] = a.optional();
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  required(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      if (t && !t[n])
        r[n] = this.shape[n];
      else {
        let s = this.shape[n];
        for (; s instanceof z; )
          s = s._def.innerType;
        r[n] = s;
      }
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  keyof() {
    return fa($.objectKeys(this.shape));
  }
}
x.create = (e, t) => new x({
  shape: () => e,
  unknownKeys: "strip",
  catchall: K.create(),
  typeName: y.ZodObject,
  ..._(t)
});
x.strictCreate = (e, t) => new x({
  shape: () => e,
  unknownKeys: "strict",
  catchall: K.create(),
  typeName: y.ZodObject,
  ..._(t)
});
x.lazycreate = (e, t) => new x({
  shape: e,
  unknownKeys: "strip",
  catchall: K.create(),
  typeName: y.ZodObject,
  ..._(t)
});
class Ie extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = this._def.options;
    function a(s) {
      for (const c of s)
        if (c.result.status === "valid")
          return c.result;
      for (const c of s)
        if (c.result.status === "dirty")
          return r.common.issues.push(...c.ctx.common.issues), c.result;
      const o = s.map((c) => new Z(c.ctx.common.issues));
      return p(r, {
        code: d.invalid_union,
        unionErrors: o
      }), m;
    }
    if (r.common.async)
      return Promise.all(n.map(async (s) => {
        const o = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await s._parseAsync({
            data: r.data,
            path: r.path,
            parent: o
          }),
          ctx: o
        };
      })).then(a);
    {
      let s;
      const o = [];
      for (const l of n) {
        const u = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        }, h = l._parseSync({
          data: r.data,
          path: r.path,
          parent: u
        });
        if (h.status === "valid")
          return h;
        h.status === "dirty" && !s && (s = { result: h, ctx: u }), u.common.issues.length && o.push(u.common.issues);
      }
      if (s)
        return r.common.issues.push(...s.ctx.common.issues), s.result;
      const c = o.map((l) => new Z(l));
      return p(r, {
        code: d.invalid_union,
        unionErrors: c
      }), m;
    }
  }
  get options() {
    return this._def.options;
  }
}
Ie.create = (e, t) => new Ie({
  options: e,
  typeName: y.ZodUnion,
  ..._(t)
});
const tt = (e) => e instanceof Le ? tt(e.schema) : e instanceof U ? tt(e.innerType()) : e instanceof Me ? [e.value] : e instanceof X ? e.options : e instanceof Ve ? Object.keys(e.enum) : e instanceof Ze ? tt(e._def.innerType) : e instanceof Ce ? [void 0] : e instanceof Re ? [null] : null;
class Ot extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.object)
      return p(r, {
        code: d.invalid_type,
        expected: f.object,
        received: r.parsedType
      }), m;
    const n = this.discriminator, a = r.data[n], s = this.optionsMap.get(a);
    return s ? r.common.async ? s._parseAsync({
      data: r.data,
      path: r.path,
      parent: r
    }) : s._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }) : (p(r, {
      code: d.invalid_union_discriminator,
      options: Array.from(this.optionsMap.keys()),
      path: [n]
    }), m);
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(t, r, n) {
    const a = /* @__PURE__ */ new Map();
    for (const s of r) {
      const o = tt(s.shape[t]);
      if (!o)
        throw new Error(`A discriminator value for key \`${t}\` could not be extracted from all schema options`);
      for (const c of o) {
        if (a.has(c))
          throw new Error(`Discriminator property ${String(t)} has duplicate value ${String(c)}`);
        a.set(c, s);
      }
    }
    return new Ot({
      typeName: y.ZodDiscriminatedUnion,
      discriminator: t,
      options: r,
      optionsMap: a,
      ..._(n)
    });
  }
}
function Wt(e, t) {
  const r = Q(e), n = Q(t);
  if (e === t)
    return { valid: !0, data: e };
  if (r === f.object && n === f.object) {
    const a = $.objectKeys(t), s = $.objectKeys(e).filter((c) => a.indexOf(c) !== -1), o = { ...e, ...t };
    for (const c of s) {
      const l = Wt(e[c], t[c]);
      if (!l.valid)
        return { valid: !1 };
      o[c] = l.data;
    }
    return { valid: !0, data: o };
  } else if (r === f.array && n === f.array) {
    if (e.length !== t.length)
      return { valid: !1 };
    const a = [];
    for (let s = 0; s < e.length; s++) {
      const o = e[s], c = t[s], l = Wt(o, c);
      if (!l.valid)
        return { valid: !1 };
      a.push(l.data);
    }
    return { valid: !0, data: a };
  } else
    return r === f.date && n === f.date && +e == +t ? { valid: !0, data: e } : { valid: !1 };
}
class Ne extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), a = (s, o) => {
      if (Ft(s) || Ft(o))
        return m;
      const c = Wt(s.value, o.value);
      return c.valid ? ((Qt(s) || Qt(o)) && r.dirty(), { status: r.value, value: c.data }) : (p(n, {
        code: d.invalid_intersection_types
      }), m);
    };
    return n.common.async ? Promise.all([
      this._def.left._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      }),
      this._def.right._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      })
    ]).then(([s, o]) => a(s, o)) : a(this._def.left._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }), this._def.right._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }));
  }
}
Ne.create = (e, t, r) => new Ne({
  left: e,
  right: t,
  typeName: y.ZodIntersection,
  ..._(r)
});
class H extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.array)
      return p(n, {
        code: d.invalid_type,
        expected: f.array,
        received: n.parsedType
      }), m;
    if (n.data.length < this._def.items.length)
      return p(n, {
        code: d.too_small,
        minimum: this._def.items.length,
        inclusive: !0,
        exact: !1,
        type: "array"
      }), m;
    !this._def.rest && n.data.length > this._def.items.length && (p(n, {
      code: d.too_big,
      maximum: this._def.items.length,
      inclusive: !0,
      exact: !1,
      type: "array"
    }), r.dirty());
    const s = [...n.data].map((o, c) => {
      const l = this._def.items[c] || this._def.rest;
      return l ? l._parse(new q(n, o, n.path, c)) : null;
    }).filter((o) => !!o);
    return n.common.async ? Promise.all(s).then((o) => R.mergeArray(r, o)) : R.mergeArray(r, s);
  }
  get items() {
    return this._def.items;
  }
  rest(t) {
    return new H({
      ...this._def,
      rest: t
    });
  }
}
H.create = (e, t) => {
  if (!Array.isArray(e))
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  return new H({
    items: e,
    typeName: y.ZodTuple,
    rest: null,
    ..._(t)
  });
};
class Pe extends b {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.object)
      return p(n, {
        code: d.invalid_type,
        expected: f.object,
        received: n.parsedType
      }), m;
    const a = [], s = this._def.keyType, o = this._def.valueType;
    for (const c in n.data)
      a.push({
        key: s._parse(new q(n, c, n.path, c)),
        value: o._parse(new q(n, n.data[c], n.path, c))
      });
    return n.common.async ? R.mergeObjectAsync(r, a) : R.mergeObjectSync(r, a);
  }
  get element() {
    return this._def.valueType;
  }
  static create(t, r, n) {
    return r instanceof b ? new Pe({
      keyType: t,
      valueType: r,
      typeName: y.ZodRecord,
      ..._(n)
    }) : new Pe({
      keyType: V.create(),
      valueType: t,
      typeName: y.ZodRecord,
      ..._(r)
    });
  }
}
class lt extends b {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.map)
      return p(n, {
        code: d.invalid_type,
        expected: f.map,
        received: n.parsedType
      }), m;
    const a = this._def.keyType, s = this._def.valueType, o = [...n.data.entries()].map(([c, l], u) => ({
      key: a._parse(new q(n, c, n.path, [u, "key"])),
      value: s._parse(new q(n, l, n.path, [u, "value"]))
    }));
    if (n.common.async) {
      const c = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const l of o) {
          const u = await l.key, h = await l.value;
          if (u.status === "aborted" || h.status === "aborted")
            return m;
          (u.status === "dirty" || h.status === "dirty") && r.dirty(), c.set(u.value, h.value);
        }
        return { status: r.value, value: c };
      });
    } else {
      const c = /* @__PURE__ */ new Map();
      for (const l of o) {
        const u = l.key, h = l.value;
        if (u.status === "aborted" || h.status === "aborted")
          return m;
        (u.status === "dirty" || h.status === "dirty") && r.dirty(), c.set(u.value, h.value);
      }
      return { status: r.value, value: c };
    }
  }
}
lt.create = (e, t, r) => new lt({
  valueType: t,
  keyType: e,
  typeName: y.ZodMap,
  ..._(r)
});
class oe extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.set)
      return p(n, {
        code: d.invalid_type,
        expected: f.set,
        received: n.parsedType
      }), m;
    const a = this._def;
    a.minSize !== null && n.data.size < a.minSize.value && (p(n, {
      code: d.too_small,
      minimum: a.minSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: a.minSize.message
    }), r.dirty()), a.maxSize !== null && n.data.size > a.maxSize.value && (p(n, {
      code: d.too_big,
      maximum: a.maxSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: a.maxSize.message
    }), r.dirty());
    const s = this._def.valueType;
    function o(l) {
      const u = /* @__PURE__ */ new Set();
      for (const h of l) {
        if (h.status === "aborted")
          return m;
        h.status === "dirty" && r.dirty(), u.add(h.value);
      }
      return { status: r.value, value: u };
    }
    const c = [...n.data.values()].map((l, u) => s._parse(new q(n, l, n.path, u)));
    return n.common.async ? Promise.all(c).then((l) => o(l)) : o(c);
  }
  min(t, r) {
    return new oe({
      ...this._def,
      minSize: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new oe({
      ...this._def,
      maxSize: { value: t, message: v.toString(r) }
    });
  }
  size(t, r) {
    return this.min(t, r).max(t, r);
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
oe.create = (e, t) => new oe({
  valueType: e,
  minSize: null,
  maxSize: null,
  typeName: y.ZodSet,
  ..._(t)
});
class pe extends b {
  constructor() {
    super(...arguments), this.validate = this.implement;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.function)
      return p(r, {
        code: d.invalid_type,
        expected: f.function,
        received: r.parsedType
      }), m;
    function n(c, l) {
      return it({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          st(),
          Oe
        ].filter((u) => !!u),
        issueData: {
          code: d.invalid_arguments,
          argumentsError: l
        }
      });
    }
    function a(c, l) {
      return it({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          st(),
          Oe
        ].filter((u) => !!u),
        issueData: {
          code: d.invalid_return_type,
          returnTypeError: l
        }
      });
    }
    const s = { errorMap: r.common.contextualErrorMap }, o = r.data;
    if (this._def.returns instanceof ve) {
      const c = this;
      return N(async function(...l) {
        const u = new Z([]), h = await c._def.args.parseAsync(l, s).catch((C) => {
          throw u.addIssue(n(l, C)), u;
        }), T = await Reflect.apply(o, this, h);
        return await c._def.returns._def.type.parseAsync(T, s).catch((C) => {
          throw u.addIssue(a(T, C)), u;
        });
      });
    } else {
      const c = this;
      return N(function(...l) {
        const u = c._def.args.safeParse(l, s);
        if (!u.success)
          throw new Z([n(l, u.error)]);
        const h = Reflect.apply(o, this, u.data), T = c._def.returns.safeParse(h, s);
        if (!T.success)
          throw new Z([a(h, T.error)]);
        return T.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...t) {
    return new pe({
      ...this._def,
      args: H.create(t).rest(se.create())
    });
  }
  returns(t) {
    return new pe({
      ...this._def,
      returns: t
    });
  }
  implement(t) {
    return this.parse(t);
  }
  strictImplement(t) {
    return this.parse(t);
  }
  static create(t, r, n) {
    return new pe({
      args: t || H.create([]).rest(se.create()),
      returns: r || se.create(),
      typeName: y.ZodFunction,
      ..._(n)
    });
  }
}
class Le extends b {
  get schema() {
    return this._def.getter();
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    return this._def.getter()._parse({ data: r.data, path: r.path, parent: r });
  }
}
Le.create = (e, t) => new Le({
  getter: e,
  typeName: y.ZodLazy,
  ..._(t)
});
class Me extends b {
  _parse(t) {
    if (t.data !== this._def.value) {
      const r = this._getOrReturnCtx(t);
      return p(r, {
        received: r.data,
        code: d.invalid_literal,
        expected: this._def.value
      }), m;
    }
    return { status: "valid", value: t.data };
  }
  get value() {
    return this._def.value;
  }
}
Me.create = (e, t) => new Me({
  value: e,
  typeName: y.ZodLiteral,
  ..._(t)
});
function fa(e, t) {
  return new X({
    values: e,
    typeName: y.ZodEnum,
    ..._(t)
  });
}
class X extends b {
  _parse(t) {
    if (typeof t.data != "string") {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        expected: $.joinValues(n),
        received: r.parsedType,
        code: d.invalid_type
      }), m;
    }
    if (this._def.values.indexOf(t.data) === -1) {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        received: r.data,
        code: d.invalid_enum_value,
        options: n
      }), m;
    }
    return N(t.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Values() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  extract(t) {
    return X.create(t);
  }
  exclude(t) {
    return X.create(this.options.filter((r) => !t.includes(r)));
  }
}
X.create = fa;
class Ve extends b {
  _parse(t) {
    const r = $.getValidEnumValues(this._def.values), n = this._getOrReturnCtx(t);
    if (n.parsedType !== f.string && n.parsedType !== f.number) {
      const a = $.objectValues(r);
      return p(n, {
        expected: $.joinValues(a),
        received: n.parsedType,
        code: d.invalid_type
      }), m;
    }
    if (r.indexOf(t.data) === -1) {
      const a = $.objectValues(r);
      return p(n, {
        received: n.data,
        code: d.invalid_enum_value,
        options: a
      }), m;
    }
    return N(t.data);
  }
  get enum() {
    return this._def.values;
  }
}
Ve.create = (e, t) => new Ve({
  values: e,
  typeName: y.ZodNativeEnum,
  ..._(t)
});
class ve extends b {
  unwrap() {
    return this._def.type;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.promise && r.common.async === !1)
      return p(r, {
        code: d.invalid_type,
        expected: f.promise,
        received: r.parsedType
      }), m;
    const n = r.parsedType === f.promise ? r.data : Promise.resolve(r.data);
    return N(n.then((a) => this._def.type.parseAsync(a, {
      path: r.path,
      errorMap: r.common.contextualErrorMap
    })));
  }
}
ve.create = (e, t) => new ve({
  type: e,
  typeName: y.ZodPromise,
  ..._(t)
});
class U extends b {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === y.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), a = this._def.effect || null, s = {
      addIssue: (o) => {
        p(n, o), o.fatal ? r.abort() : r.dirty();
      },
      get path() {
        return n.path;
      }
    };
    if (s.addIssue = s.addIssue.bind(s), a.type === "preprocess") {
      const o = a.transform(n.data, s);
      return n.common.issues.length ? {
        status: "dirty",
        value: n.data
      } : n.common.async ? Promise.resolve(o).then((c) => this._def.schema._parseAsync({
        data: c,
        path: n.path,
        parent: n
      })) : this._def.schema._parseSync({
        data: o,
        path: n.path,
        parent: n
      });
    }
    if (a.type === "refinement") {
      const o = (c) => {
        const l = a.refinement(c, s);
        if (n.common.async)
          return Promise.resolve(l);
        if (l instanceof Promise)
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        return c;
      };
      if (n.common.async === !1) {
        const c = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return c.status === "aborted" ? m : (c.status === "dirty" && r.dirty(), o(c.value), { status: r.value, value: c.value });
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((c) => c.status === "aborted" ? m : (c.status === "dirty" && r.dirty(), o(c.value).then(() => ({ status: r.value, value: c.value }))));
    }
    if (a.type === "transform")
      if (n.common.async === !1) {
        const o = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        if (!ke(o))
          return o;
        const c = a.transform(o.value, s);
        if (c instanceof Promise)
          throw new Error("Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.");
        return { status: r.value, value: c };
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((o) => ke(o) ? Promise.resolve(a.transform(o.value, s)).then((c) => ({ status: r.value, value: c })) : o);
    $.assertNever(a);
  }
}
U.create = (e, t, r) => new U({
  schema: e,
  typeName: y.ZodEffects,
  effect: t,
  ..._(r)
});
U.createWithPreprocess = (e, t, r) => new U({
  schema: t,
  effect: { type: "preprocess", transform: e },
  typeName: y.ZodEffects,
  ..._(r)
});
class z extends b {
  _parse(t) {
    return this._getType(t) === f.undefined ? N(void 0) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
z.create = (e, t) => new z({
  innerType: e,
  typeName: y.ZodOptional,
  ..._(t)
});
class ce extends b {
  _parse(t) {
    return this._getType(t) === f.null ? N(null) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
ce.create = (e, t) => new ce({
  innerType: e,
  typeName: y.ZodNullable,
  ..._(t)
});
class Ze extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    let n = r.data;
    return r.parsedType === f.undefined && (n = this._def.defaultValue()), this._def.innerType._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
Ze.create = (e, t) => new Ze({
  innerType: e,
  typeName: y.ZodDefault,
  defaultValue: typeof t.default == "function" ? t.default : () => t.default,
  ..._(t)
});
class dt extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = {
      ...r,
      common: {
        ...r.common,
        issues: []
      }
    }, a = this._def.innerType._parse({
      data: n.data,
      path: n.path,
      parent: {
        ...n
      }
    });
    return ot(a) ? a.then((s) => ({
      status: "valid",
      value: s.status === "valid" ? s.value : this._def.catchValue({
        get error() {
          return new Z(n.common.issues);
        },
        input: n.data
      })
    })) : {
      status: "valid",
      value: a.status === "valid" ? a.value : this._def.catchValue({
        get error() {
          return new Z(n.common.issues);
        },
        input: n.data
      })
    };
  }
  removeCatch() {
    return this._def.innerType;
  }
}
dt.create = (e, t) => new dt({
  innerType: e,
  typeName: y.ZodCatch,
  catchValue: typeof t.catch == "function" ? t.catch : () => t.catch,
  ..._(t)
});
class ft extends b {
  _parse(t) {
    if (this._getType(t) !== f.nan) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.nan,
        received: n.parsedType
      }), m;
    }
    return { status: "valid", value: t.data };
  }
}
ft.create = (e) => new ft({
  typeName: y.ZodNaN,
  ..._(e)
});
const yh = Symbol("zod_brand");
class pa extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = r.data;
    return this._def.type._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class qe extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.common.async)
      return (async () => {
        const s = await this._def.in._parseAsync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return s.status === "aborted" ? m : s.status === "dirty" ? (r.dirty(), da(s.value)) : this._def.out._parseAsync({
          data: s.value,
          path: n.path,
          parent: n
        });
      })();
    {
      const a = this._def.in._parseSync({
        data: n.data,
        path: n.path,
        parent: n
      });
      return a.status === "aborted" ? m : a.status === "dirty" ? (r.dirty(), {
        status: "dirty",
        value: a.value
      }) : this._def.out._parseSync({
        data: a.value,
        path: n.path,
        parent: n
      });
    }
  }
  static create(t, r) {
    return new qe({
      in: t,
      out: r,
      typeName: y.ZodPipeline
    });
  }
}
class pt extends b {
  _parse(t) {
    const r = this._def.innerType._parse(t);
    return ke(r) && (r.value = Object.freeze(r.value)), r;
  }
}
pt.create = (e, t) => new pt({
  innerType: e,
  typeName: y.ZodReadonly,
  ..._(t)
});
const ha = (e, t = {}, r) => e ? he.create().superRefine((n, a) => {
  var s, o;
  if (!e(n)) {
    const c = typeof t == "function" ? t(n) : typeof t == "string" ? { message: t } : t, l = (o = (s = c.fatal) !== null && s !== void 0 ? s : r) !== null && o !== void 0 ? o : !0, u = typeof c == "string" ? { message: c } : c;
    a.addIssue({ code: "custom", ...u, fatal: l });
  }
}) : he.create(), gh = {
  object: x.lazycreate
};
var y;
(function(e) {
  e.ZodString = "ZodString", e.ZodNumber = "ZodNumber", e.ZodNaN = "ZodNaN", e.ZodBigInt = "ZodBigInt", e.ZodBoolean = "ZodBoolean", e.ZodDate = "ZodDate", e.ZodSymbol = "ZodSymbol", e.ZodUndefined = "ZodUndefined", e.ZodNull = "ZodNull", e.ZodAny = "ZodAny", e.ZodUnknown = "ZodUnknown", e.ZodNever = "ZodNever", e.ZodVoid = "ZodVoid", e.ZodArray = "ZodArray", e.ZodObject = "ZodObject", e.ZodUnion = "ZodUnion", e.ZodDiscriminatedUnion = "ZodDiscriminatedUnion", e.ZodIntersection = "ZodIntersection", e.ZodTuple = "ZodTuple", e.ZodRecord = "ZodRecord", e.ZodMap = "ZodMap", e.ZodSet = "ZodSet", e.ZodFunction = "ZodFunction", e.ZodLazy = "ZodLazy", e.ZodLiteral = "ZodLiteral", e.ZodEnum = "ZodEnum", e.ZodEffects = "ZodEffects", e.ZodNativeEnum = "ZodNativeEnum", e.ZodOptional = "ZodOptional", e.ZodNullable = "ZodNullable", e.ZodDefault = "ZodDefault", e.ZodCatch = "ZodCatch", e.ZodPromise = "ZodPromise", e.ZodBranded = "ZodBranded", e.ZodPipeline = "ZodPipeline", e.ZodReadonly = "ZodReadonly";
})(y || (y = {}));
const mh = (e, t = {
  message: `Input not instance of ${e.name}`
}) => ha((r) => r instanceof e, t), va = V.create, ya = J.create, _h = ft.create, bh = Y.create, ga = je.create, Th = ie.create, $h = ct.create, Ah = Ce.create, wh = Re.create, Sh = he.create, xh = se.create, Eh = K.create, Oh = ut.create, kh = D.create, jh = x.create, Ch = x.strictCreate, Rh = Ie.create, Ih = Ot.create, Nh = Ne.create, Ph = H.create, Lh = Pe.create, Mh = lt.create, Vh = oe.create, Zh = pe.create, Dh = Le.create, Uh = Me.create, qh = X.create, Hh = Ve.create, Bh = ve.create, pn = U.create, Gh = z.create, zh = ce.create, Kh = U.createWithPreprocess, Fh = qe.create, Qh = () => va().optional(), Wh = () => ya().optional(), Jh = () => ga().optional(), Yh = {
  string: (e) => V.create({ ...e, coerce: !0 }),
  number: (e) => J.create({ ...e, coerce: !0 }),
  boolean: (e) => je.create({
    ...e,
    coerce: !0
  }),
  bigint: (e) => Y.create({ ...e, coerce: !0 }),
  date: (e) => ie.create({ ...e, coerce: !0 })
}, Xh = m;
var i = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: Oe,
  setErrorMap: nh,
  getErrorMap: st,
  makeIssue: it,
  EMPTY_PATH: ah,
  addIssueToContext: p,
  ParseStatus: R,
  INVALID: m,
  DIRTY: da,
  OK: N,
  isAborted: Ft,
  isDirty: Qt,
  isValid: ke,
  isAsync: ot,
  get util() {
    return $;
  },
  get objectUtil() {
    return Kt;
  },
  ZodParsedType: f,
  getParsedType: Q,
  ZodType: b,
  ZodString: V,
  ZodNumber: J,
  ZodBigInt: Y,
  ZodBoolean: je,
  ZodDate: ie,
  ZodSymbol: ct,
  ZodUndefined: Ce,
  ZodNull: Re,
  ZodAny: he,
  ZodUnknown: se,
  ZodNever: K,
  ZodVoid: ut,
  ZodArray: D,
  ZodObject: x,
  ZodUnion: Ie,
  ZodDiscriminatedUnion: Ot,
  ZodIntersection: Ne,
  ZodTuple: H,
  ZodRecord: Pe,
  ZodMap: lt,
  ZodSet: oe,
  ZodFunction: pe,
  ZodLazy: Le,
  ZodLiteral: Me,
  ZodEnum: X,
  ZodNativeEnum: Ve,
  ZodPromise: ve,
  ZodEffects: U,
  ZodTransformer: U,
  ZodOptional: z,
  ZodNullable: ce,
  ZodDefault: Ze,
  ZodCatch: dt,
  ZodNaN: ft,
  BRAND: yh,
  ZodBranded: pa,
  ZodPipeline: qe,
  ZodReadonly: pt,
  custom: ha,
  Schema: b,
  ZodSchema: b,
  late: gh,
  get ZodFirstPartyTypeKind() {
    return y;
  },
  coerce: Yh,
  any: Sh,
  array: kh,
  bigint: bh,
  boolean: ga,
  date: Th,
  discriminatedUnion: Ih,
  effect: pn,
  enum: qh,
  function: Zh,
  instanceof: mh,
  intersection: Nh,
  lazy: Dh,
  literal: Uh,
  map: Mh,
  nan: _h,
  nativeEnum: Hh,
  never: Eh,
  null: wh,
  nullable: zh,
  number: ya,
  object: jh,
  oboolean: Jh,
  onumber: Wh,
  optional: Gh,
  ostring: Qh,
  pipeline: Fh,
  preprocess: Kh,
  promise: Bh,
  record: Lh,
  set: Vh,
  strictObject: Ch,
  string: va,
  symbol: $h,
  transformer: pn,
  tuple: Ph,
  undefined: Ah,
  union: Rh,
  unknown: xh,
  void: Oh,
  NEVER: Xh,
  ZodIssueCode: d,
  quotelessJson: rh,
  ZodError: Z
}), M = (e) => e, ev = class {
  /**
   * @package
   */
  constructor(e, t, r) {
    this.versionMap = e, this.latestVersion = t, this.getVersion = r;
  }
  /**
   * Returns whether the given data is a valid entity of any version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of any version of the entity.
   */
  is(e) {
    let t = this.getVersion(e);
    if (t === null)
      return !1;
    const r = this.versionMap[t];
    return r ? r.schema.safeParse(e).success : !1;
  }
  /**
   * Returns whether the given data is a valid entity of the latest version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of the latest version of the entity.
   */
  isLatest(e) {
    return this.versionMap[this.latestVersion].schema.safeParse(e).success;
  }
  /**
   * Similar to Zod's `safeParse` method, but also migrates the data to the latest version.
   * @param data The data to parse
   * @returns The result from parsing data, if successful, older versions are migrated to the latest version
   */
  safeParse(e) {
    const t = this.getVersion(e);
    if (t === null)
      return { type: "err", error: { type: "VER_CHECK_FAIL" } };
    const r = this.versionMap[t];
    if (!r)
      return { type: "err", error: { type: "INVALID_VER" } };
    const n = r.schema.safeParse(e);
    if (!n.success)
      return {
        type: "err",
        error: {
          type: "GIVEN_VER_VALIDATION_FAIL",
          version: t,
          versionDef: r,
          error: n.error
        }
      };
    let a = e;
    for (let s = t + 1; s <= this.latestVersion; s++) {
      const o = this.versionMap[s];
      if (!o)
        return {
          type: "err",
          error: { type: "BUG_NO_INTERMEDIATE_FOUND", missingVer: s }
        };
      if (o.initial)
        return {
          type: "err",
          error: { type: "BUG_INTERMEDIATE_MARKED_INITIAL", ver: s }
        };
      a = o.up(a);
    }
    return { type: "ok", value: a };
  }
};
function kt(e) {
  return new ev(e.versionMap, e.latestVersion, e.getVersion);
}
function ht(e) {
  return i.custom((t) => e.is(t)).transform((t) => {
    const r = e.safeParse(t);
    if (r.type !== "ok")
      throw new Error(
        "Invalid entity definition. `entity.is` returned success, safeParse failed."
      );
    return r.value;
  });
}
const tv = i.object({
  id: i.optional(i.string()),
  // Firebase Firestore ID
  url: i.string(),
  path: i.string(),
  headers: i.array(
    i.object({
      key: i.string(),
      value: i.string(),
      active: i.boolean()
    })
  ),
  params: i.array(
    i.object({
      key: i.string(),
      value: i.string(),
      active: i.boolean()
    })
  ),
  name: i.string(),
  method: i.string(),
  preRequestScript: i.string(),
  testScript: i.string(),
  contentType: i.string(),
  body: i.string(),
  rawParams: i.optional(i.string()),
  auth: i.optional(i.string()),
  httpUser: i.optional(i.string()),
  httpPassword: i.optional(i.string()),
  bearerToken: i.optional(i.string())
}), hn = M({
  initial: !0,
  schema: tv
}), ma = i.object({
  key: i.string(),
  active: i.boolean()
}).and(
  i.union([
    i.object({
      isFile: i.literal(!0),
      value: i.array(i.instanceof(Blob).nullable())
    }),
    i.object({
      isFile: i.literal(!1),
      value: i.string()
    })
  ])
), pm = i.object({
  contentType: i.literal("multipart/form-data"),
  body: i.array(ma)
}), _a = i.union([
  i.object({
    contentType: i.literal(null),
    body: i.literal(null).catch(null)
  }),
  i.object({
    contentType: i.literal("multipart/form-data"),
    body: i.array(ma).catch([])
  }),
  i.object({
    contentType: i.union([
      i.literal("application/json"),
      i.literal("application/ld+json"),
      i.literal("application/hal+json"),
      i.literal("application/vnd.api+json"),
      i.literal("application/xml"),
      i.literal("application/x-www-form-urlencoded"),
      i.literal("text/html"),
      i.literal("text/plain")
    ]),
    body: i.string().catch("")
  })
]), fr = i.object({
  authType: i.literal("none")
}), pr = i.object({
  authType: i.literal("basic"),
  username: i.string().catch(""),
  password: i.string().catch("")
}), hr = i.object({
  authType: i.literal("bearer"),
  token: i.string().catch("")
}), rv = i.object({
  authType: i.literal("oauth-2"),
  token: i.string().catch(""),
  oidcDiscoveryURL: i.string().catch(""),
  authURL: i.string().catch(""),
  accessTokenURL: i.string().catch(""),
  clientID: i.string().catch(""),
  scope: i.string().catch("")
}), vr = i.object({
  authType: i.literal("api-key"),
  key: i.string().catch(""),
  value: i.string().catch(""),
  addTo: i.string().catch("Headers")
}), yr = i.object({
  authType: i.literal("inherit")
}), nv = i.discriminatedUnion("authType", [
  fr,
  yr,
  pr,
  hr,
  rv,
  vr
]).and(
  i.object({
    authActive: i.boolean()
  })
), ba = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0)
  })
), gr = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0)
  })
), Ta = i.object({
  v: i.literal("1"),
  id: i.optional(i.string()),
  // Firebase Firestore ID
  name: i.string(),
  method: i.string(),
  endpoint: i.string(),
  params: ba,
  headers: gr,
  preRequestScript: i.string().catch(""),
  testScript: i.string().catch(""),
  auth: nv,
  body: _a
});
function av(e) {
  return {
    contentType: "application/json",
    body: e.contentType === "application/json" ? e.rawParams ?? "" : ""
  };
}
function sv(e) {
  return !e.auth || e.auth === "None" ? {
    authType: "none",
    authActive: !0
  } : e.auth === "Basic Auth" ? {
    authType: "basic",
    authActive: !0,
    username: e.httpUser ?? "",
    password: e.httpPassword ?? ""
  } : e.auth === "Bearer Token" ? {
    authType: "bearer",
    authActive: !0,
    token: e.bearerToken ?? ""
  } : { authType: "none", authActive: !0 };
}
const iv = M({
  initial: !1,
  schema: Ta,
  up(e) {
    const {
      url: t,
      path: r,
      headers: n,
      params: a,
      name: s,
      method: o,
      preRequestScript: c,
      testScript: l
    } = e, u = `${t}${r}`, h = av(e), T = sv(e), A = {
      v: "1",
      endpoint: u,
      headers: n,
      params: a,
      name: s,
      method: o,
      preRequestScript: c,
      testScript: l,
      body: h,
      auth: T
    };
    return e.id && (A.id = e.id), A;
  }
}), $a = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0)
  })
), Aa = Ta.extend({
  v: i.literal("2"),
  requestVariables: $a
}), ov = M({
  initial: !1,
  schema: Aa,
  up(e) {
    return {
      ...e,
      v: "2",
      requestVariables: []
    };
  }
}), cv = i.object({
  grantType: i.literal("AUTHORIZATION_CODE"),
  authEndpoint: i.string().trim(),
  tokenEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  clientSecret: i.string().trim(),
  scopes: i.string().trim().optional(),
  token: i.string().catch(""),
  isPKCE: i.boolean(),
  codeVerifierMethod: i.union([i.literal("plain"), i.literal("S256")]).optional()
}), uv = i.object({
  grantType: i.literal("CLIENT_CREDENTIALS"),
  authEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  clientSecret: i.string().trim(),
  scopes: i.string().trim().optional(),
  token: i.string().catch("")
}), lv = i.object({
  grantType: i.literal("PASSWORD"),
  authEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  clientSecret: i.string().trim(),
  scopes: i.string().trim().optional(),
  username: i.string().trim(),
  password: i.string().trim(),
  token: i.string().catch("")
}), dv = i.object({
  grantType: i.literal("IMPLICIT"),
  authEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  scopes: i.string().trim().optional(),
  token: i.string().catch("")
}), jt = i.object({
  authType: i.literal("oauth-2"),
  grantTypeInfo: i.discriminatedUnion("grantType", [
    cv,
    uv,
    lv,
    dv
  ]),
  addTo: i.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), fv = i.discriminatedUnion("authType", [
  fr,
  yr,
  pr,
  hr,
  jt,
  vr
]).and(
  i.object({
    authActive: i.boolean()
  })
), wa = Aa.extend({
  v: i.literal("3"),
  auth: fv
}), pv = M({
  initial: !1,
  schema: wa,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: a, authURL: s } = e.auth;
      return {
        ...e,
        v: "3",
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: s,
            tokenEndpoint: r,
            clientID: a,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: "3",
      auth: {
        ...e.auth
      }
    };
  }
}), hv = vr.extend({
  addTo: i.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), mr = i.discriminatedUnion("authType", [
  fr,
  yr,
  pr,
  hr,
  jt,
  hv
]).and(
  i.object({
    authActive: i.boolean()
  })
), vv = wa.extend({
  v: i.literal("4"),
  auth: mr
}), yv = M({
  schema: vv,
  initial: !1,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: "4",
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      auth: {
        ...e.auth
      },
      v: "4"
    };
  }
});
var gv = "__lodash_hash_undefined__";
function mv(e) {
  return this.__data__.set(e, gv), this;
}
var _v = mv;
function bv(e) {
  return this.__data__.has(e);
}
var Tv = bv, $v = Hn, Av = _v, wv = Tv;
function vt(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new $v(); ++t < r; )
    this.add(e[t]);
}
vt.prototype.add = vt.prototype.push = Av;
vt.prototype.has = wv;
var Sv = vt;
function xv(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
var Ev = xv;
function Ov(e, t) {
  return e.has(t);
}
var kv = Ov, jv = Sv, Cv = Ev, Rv = kv, Iv = 1, Nv = 2;
function Pv(e, t, r, n, a, s) {
  var o = r & Iv, c = e.length, l = t.length;
  if (c != l && !(o && l > c))
    return !1;
  var u = s.get(e), h = s.get(t);
  if (u && h)
    return u == t && h == e;
  var T = -1, A = !0, C = r & Nv ? new jv() : void 0;
  for (s.set(e, t), s.set(t, e); ++T < c; ) {
    var P = e[T], L = t[T];
    if (n)
      var j = o ? n(L, P, T, t, e, s) : n(P, L, T, e, t, s);
    if (j !== void 0) {
      if (j)
        continue;
      A = !1;
      break;
    }
    if (C) {
      if (!Cv(t, function(I, ne) {
        if (!Rv(C, ne) && (P === I || a(P, I, r, n, s)))
          return C.push(ne);
      })) {
        A = !1;
        break;
      }
    } else if (!(P === L || a(P, L, r, n, s))) {
      A = !1;
      break;
    }
  }
  return s.delete(e), s.delete(t), A;
}
var Sa = Pv;
function Lv(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, a) {
    r[++t] = [a, n];
  }), r;
}
var Mv = Lv;
function Vv(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var Zv = Vv, vn = Tt, yn = ia, Dv = rr, Uv = Sa, qv = Mv, Hv = Zv, Bv = 1, Gv = 2, zv = "[object Boolean]", Kv = "[object Date]", Fv = "[object Error]", Qv = "[object Map]", Wv = "[object Number]", Jv = "[object RegExp]", Yv = "[object Set]", Xv = "[object String]", ey = "[object Symbol]", ty = "[object ArrayBuffer]", ry = "[object DataView]", gn = vn ? vn.prototype : void 0, Zt = gn ? gn.valueOf : void 0;
function ny(e, t, r, n, a, s, o) {
  switch (r) {
    case ry:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case ty:
      return !(e.byteLength != t.byteLength || !s(new yn(e), new yn(t)));
    case zv:
    case Kv:
    case Wv:
      return Dv(+e, +t);
    case Fv:
      return e.name == t.name && e.message == t.message;
    case Jv:
    case Xv:
      return e == t + "";
    case Qv:
      var c = qv;
    case Yv:
      var l = n & Bv;
      if (c || (c = Hv), e.size != t.size && !l)
        return !1;
      var u = o.get(e);
      if (u)
        return u == t;
      n |= Gv, o.set(e, t);
      var h = Uv(c(e), c(t), n, a, s, o);
      return o.delete(e), h;
    case ey:
      if (Zt)
        return Zt.call(e) == Zt.call(t);
  }
  return !1;
}
var ay = ny, mn = aa, sy = 1, iy = Object.prototype, oy = iy.hasOwnProperty;
function cy(e, t, r, n, a, s) {
  var o = r & sy, c = mn(e), l = c.length, u = mn(t), h = u.length;
  if (l != h && !o)
    return !1;
  for (var T = l; T--; ) {
    var A = c[T];
    if (!(o ? A in t : oy.call(t, A)))
      return !1;
  }
  var C = s.get(e), P = s.get(t);
  if (C && P)
    return C == t && P == e;
  var L = !0;
  s.set(e, t), s.set(t, e);
  for (var j = o; ++T < l; ) {
    A = c[T];
    var I = e[A], ne = t[A];
    if (n)
      var Vr = o ? n(ne, I, A, t, e, s) : n(I, ne, A, e, t, s);
    if (!(Vr === void 0 ? I === ne || a(I, ne, r, n, s) : Vr)) {
      L = !1;
      break;
    }
    j || (j = A == "constructor");
  }
  if (L && !j) {
    var We = e.constructor, Je = t.constructor;
    We != Je && "constructor" in e && "constructor" in t && !(typeof We == "function" && We instanceof We && typeof Je == "function" && Je instanceof Je) && (L = !1);
  }
  return s.delete(e), s.delete(t), L;
}
var uy = cy, Dt = Bn, ly = Sa, dy = ay, fy = uy, _n = Et, bn = xt, Tn = ar, py = Qn, hy = 1, $n = "[object Arguments]", An = "[object Array]", Xe = "[object Object]", vy = Object.prototype, wn = vy.hasOwnProperty;
function yy(e, t, r, n, a, s) {
  var o = bn(e), c = bn(t), l = o ? An : _n(e), u = c ? An : _n(t);
  l = l == $n ? Xe : l, u = u == $n ? Xe : u;
  var h = l == Xe, T = u == Xe, A = l == u;
  if (A && Tn(e)) {
    if (!Tn(t))
      return !1;
    o = !0, h = !1;
  }
  if (A && !h)
    return s || (s = new Dt()), o || py(e) ? ly(e, t, r, n, a, s) : dy(e, t, l, r, n, a, s);
  if (!(r & hy)) {
    var C = h && wn.call(e, "__wrapped__"), P = T && wn.call(t, "__wrapped__");
    if (C || P) {
      var L = C ? e.value() : e, j = P ? t.value() : t;
      return s || (s = new Dt()), a(L, j, r, n, s);
    }
  }
  return A ? (s || (s = new Dt()), fy(e, t, r, n, a, s)) : !1;
}
var gy = yy, my = gy, Sn = we;
function xa(e, t, r, n, a) {
  return e === t ? !0 : e == null || t == null || !Sn(e) && !Sn(t) ? e !== e && t !== t : my(e, t, r, n, xa, a);
}
var _y = xa, by = _y;
function Ty(e, t) {
  return by(e, t);
}
var $y = Ty;
const Ay = /* @__PURE__ */ Vn($y), wy = (e) => ({
  equals(t, r) {
    return t !== void 0 && r !== void 0 ? e.equals(t, r) : t === void 0 && r === void 0;
  }
}), Ut = (e, t) => ({
  equals(r, n) {
    return t.equals(e(r), e(n));
  }
}), Ee = {
  equals(e, t) {
    return Ay(e, t);
  }
}, Sy = {
  "application/json": "json",
  "application/ld+json": "json",
  "application/hal+json": "json",
  "application/vnd.api+json": "json",
  "application/xml": "xml",
  "application/x-www-form-urlencoded": "multipart",
  "multipart/form-data": "multipart",
  "text/html": "html",
  "text/plain": "plain"
}, hm = Object.keys(Sy), xy = i.object({
  // v is a stringified number
  v: i.string().regex(/^\d+$/).transform(Number)
}), Ct = kt({
  latestVersion: 4,
  versionMap: {
    0: hn,
    1: iv,
    2: ov,
    3: pv,
    4: yv
  },
  getVersion(e) {
    const t = xy.safeParse(e);
    return t.success ? t.data.v : hn.schema.safeParse(e).success ? 0 : null;
  }
}), Ey = os({
  id: wy(F),
  v: F,
  auth: Ee,
  body: Ee,
  endpoint: F,
  headers: Ut(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    Ee
  ),
  params: Ut(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    Ee
  ),
  method: F,
  name: F,
  preRequestScript: F,
  testScript: F,
  requestVariables: Ut(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    Ee
  )
}), Oy = "4", vm = Ey.equals;
function ym(e, t) {
  const r = th(t);
  if (e && typeof e == "object") {
    if ("id" in e && typeof e.id == "string" && (r.id = e.id), "name" in e && typeof e.name == "string" && (r.name = e.name), "method" in e && typeof e.method == "string" && (r.method = e.method), "endpoint" in e && typeof e.endpoint == "string" && (r.endpoint = e.endpoint), "preRequestScript" in e && typeof e.preRequestScript == "string" && (r.preRequestScript = e.preRequestScript), "testScript" in e && typeof e.testScript == "string" && (r.testScript = e.testScript), "body" in e) {
      const n = _a.safeParse(e.body);
      n.success && (r.body = n.data);
    }
    if ("auth" in e) {
      const n = mr.safeParse(e.auth);
      n.success && (r.auth = n.data);
    }
    if ("params" in e) {
      const n = ba.safeParse(e.params);
      n.success && (r.params = n.data);
    }
    if ("headers" in e) {
      const n = gr.safeParse(e.headers);
      n.success && (r.headers = n.data);
    }
    if ("requestVariables" in e) {
      const n = $a.safeParse(e.requestVariables);
      n.success && (r.requestVariables = n.data);
    }
  }
  return r;
}
function gm(e) {
  return {
    v: Oy,
    ...e
  };
}
function ky() {
  return {
    v: "4",
    endpoint: "https://echo.hoppscotch.io",
    name: "Untitled",
    params: [],
    headers: [],
    method: "GET",
    auth: {
      authType: "inherit",
      authActive: !0
    },
    preRequestScript: "",
    testScript: "",
    body: {
      contentType: null,
      body: null
    },
    requestVariables: []
  };
}
function mm(e) {
  return Ct.isLatest(e);
}
function jy(e) {
  const t = Ct.safeParse(e);
  return t.type === "ok" ? t.value : ky();
}
const _r = i.object({
  key: i.string().catch(""),
  value: i.string().catch(""),
  active: i.boolean().catch(!0)
}), Cy = i.object({
  v: i.literal(1),
  name: i.string(),
  url: i.string(),
  headers: i.array(_r).catch([]),
  query: i.string(),
  variables: i.string()
}), Ry = M({
  initial: !0,
  schema: Cy
}), br = i.object({
  authType: i.literal("none")
}), Tr = i.object({
  authType: i.literal("basic"),
  username: i.string().catch(""),
  password: i.string().catch("")
}), $r = i.object({
  authType: i.literal("bearer"),
  token: i.string().catch("")
}), Iy = i.object({
  authType: i.literal("oauth-2"),
  token: i.string().catch(""),
  oidcDiscoveryURL: i.string().catch(""),
  authURL: i.string().catch(""),
  accessTokenURL: i.string().catch(""),
  clientID: i.string().catch(""),
  scope: i.string().catch("")
}), Ar = i.object({
  authType: i.literal("api-key"),
  key: i.string().catch(""),
  value: i.string().catch(""),
  addTo: i.string().catch("Headers")
}), wr = i.object({
  authType: i.literal("inherit")
}), Ny = i.discriminatedUnion("authType", [
  br,
  Tr,
  $r,
  Iy,
  Ar,
  wr
]).and(
  i.object({
    authActive: i.boolean()
  })
), Ea = i.object({
  id: i.optional(i.string()),
  v: i.literal(2),
  name: i.string(),
  url: i.string(),
  headers: i.array(_r).catch([]),
  query: i.string(),
  variables: i.string(),
  auth: Ny
}), Py = M({
  initial: !1,
  schema: Ea,
  up(e) {
    return {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "none"
      }
    };
  }
}), Ly = i.discriminatedUnion("authType", [
  br,
  wr,
  Tr,
  $r,
  Ar,
  jt
  // both rest and gql have the same auth type for oauth2
]).and(
  i.object({
    authActive: i.boolean()
  })
), Oa = Ea.extend({
  v: i.literal(3),
  auth: Ly
}), My = M({
  initial: !1,
  schema: Oa,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: a, authURL: s } = e.auth;
      return {
        ...e,
        v: 3,
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: s,
            tokenEndpoint: r,
            clientID: a,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: 3,
      auth: {
        ...e.auth
      }
    };
  }
}), Vy = Ar.extend({
  addTo: i.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), ka = i.discriminatedUnion("authType", [
  br,
  wr,
  Tr,
  $r,
  Vy,
  jt
  // both rest and gql have the same auth type for oauth2
]).and(
  i.object({
    authActive: i.boolean()
  })
), Zy = Oa.extend({
  v: i.literal(4),
  auth: ka
}), Dy = M({
  initial: !1,
  schema: Zy,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: 4,
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      v: 4,
      auth: {
        ...e.auth
      }
    };
  }
}), ja = 4, Uy = i.object({
  v: i.number()
}), Sr = kt({
  latestVersion: 4,
  versionMap: {
    1: Ry,
    2: Py,
    3: My,
    4: Dy
  },
  getVersion(e) {
    const t = Uy.safeParse(e);
    return t.success ? t.data.v : null;
  }
}), qy = `
query Request {
  method
  url
  headers {
    key
    value
  }
}`.trim();
function Hy() {
  return {
    v: ja,
    name: "Untitled",
    url: "https://echo.hoppscotch.io/graphql",
    headers: [],
    variables: `
{
  "id": "1"
}`.trim(),
    query: qy,
    auth: {
      authType: "inherit",
      authActive: !0
    }
  };
}
function By(e) {
  const t = Sr.safeParse(e);
  return t.type === "ok" ? t.value : Hy();
}
function _m(e) {
  return {
    v: ja,
    ...e
  };
}
const Gy = i.object({
  v: i.literal(1),
  id: i.optional(i.string()),
  // For Firestore ID data
  name: i.string(),
  requests: i.array(
    i.lazy(
      () => i.union([
        ht(Ct),
        ht(Sr)
      ])
    )
  )
}), Ca = Gy.extend({
  folders: i.lazy(() => i.array(Ca))
}), xn = M({
  initial: !0,
  schema: Ca
}), zy = i.object({
  v: i.literal(2),
  id: i.optional(i.string()),
  // For Firestore ID data
  name: i.string(),
  requests: i.array(
    i.lazy(
      () => i.union([
        ht(Ct),
        ht(Sr)
      ])
    )
  ),
  auth: i.union([mr, ka]),
  headers: i.union([gr, i.array(_r)])
}), Ra = zy.extend({
  folders: i.lazy(() => i.array(Ra))
}), Ky = M({
  initial: !1,
  schema: Ra,
  up(e) {
    const t = {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "inherit"
      },
      headers: []
    };
    return e.id && (t.id = e.id), t;
  }
}), Fy = i.object({
  v: i.number()
}), bm = kt({
  latestVersion: 2,
  versionMap: {
    1: xn,
    2: Ky
  },
  getVersion(e) {
    const t = Fy.safeParse(e);
    return t.success ? t.data.v : xn.schema.safeParse(e).success ? 1 : null;
  }
}), Qy = 2;
function Ia(e) {
  return {
    v: Qy,
    ...e
  };
}
function Wy(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(Wy), n = (e.requests ?? []).map(jy), a = e.auth ?? { authType: "inherit", authActive: !0 }, s = e.headers ?? [], o = Ia({
    name: t,
    folders: r,
    requests: n,
    auth: a,
    headers: s
  });
  return e.id && (o.id = e.id), o;
}
function Jy(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(Jy), n = (e.requests ?? []).map(By), a = e.auth ?? { authType: "inherit", authActive: !0 }, s = e.headers ?? [], o = Ia({
    name: t,
    folders: r,
    requests: n,
    auth: a,
    headers: s
  });
  return e.id && (o.id = e.id), o;
}
var te = function(e) {
  return function(t) {
    return !e(t);
  };
};
globalThis && globalThis.__spreadArray;
var Yy = ws, Xy = $s;
function Na(e, t) {
  return t === void 0 ? function(r) {
    return Na(e, r);
  } : Xy(e, t) ? Nn : Pn(t[e]);
}
var Pa = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, eg = function(e) {
  return function(t) {
    for (var r = [], n = 0; n < t.length; n++) {
      var a = e(n, t[n]);
      In(a) && r.push(a.value);
    }
    return r;
  };
}, tg = function(e) {
  return eg(function(t, r) {
    return e(r);
  });
}, La = function(e) {
  return e.slice();
}, rg = Ts, En = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
}, ng = function(e) {
  return function(t) {
    return En(En([], t, !0), [e], !1);
  };
}, ag = ng, sg = function(e) {
  return [e];
}, ig = ag, og = Na, cg = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, ug = function() {
  return {
    concat: function(e, t) {
      return e.concat(t);
    }
  };
}, lg = function() {
  return {
    concat: ug().concat,
    empty: []
  };
}, Rt = Nn, He = Pn;
function Ma(e) {
  return function(t) {
    return e(t) ? He(t) : Rt;
  };
}
var Jt = function(e) {
  return function(t) {
    return Va(t) ? Rt : He(e(t.value));
  };
}, dg = In, Va = function(e) {
  return e._tag === "None";
}, fg = function(e, t) {
  return function(r) {
    return Va(r) ? e() : t(r.value);
  };
}, pg = fg, xr = pg, hg = function(e, t) {
  for (var r = t(e); r._tag === "Left"; )
    r = t(r.left);
  return r.right;
}, re = ls, B = ds, vg = /* @__PURE__ */ ss(2, function(e, t) {
  return ye(e) ? e : t(e.right);
}), Be = function(e) {
  return function(t) {
    return ye(t) ? t : B(e(t.right));
  };
}, It = function(e) {
  return function(t) {
    return ye(t) ? re(e(t.left)) : t;
  };
}, ye = cs, yg = us, gg = function(e) {
  return function(t) {
    return ye(t) ? e(t.left) : t.right;
  };
}, Er = gg, On = vg, yt = globalThis && globalThis.__assign || function() {
  return yt = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (e[a] = t[a]);
    }
    return e;
  }, yt.apply(this, arguments);
}, Ge = function(e, t, r) {
  return B({
    value: e,
    next: t,
    start: r
  });
}, ze = function(e, t, r) {
  return t === void 0 && (t = []), r === void 0 && (r = !1), re({
    input: e,
    expected: t,
    fatal: r
  });
}, mg = function(e, t) {
  return yt(yt({}, e), { expected: t });
}, _g = function(e, t) {
  return bg().concat(e, t);
}, bg = function() {
  return {
    concat: function(e, t) {
      return e.input.cursor < t.input.cursor ? ms().concat(e, t) : e.input.cursor > t.input.cursor ? Lt().concat(e, t) : gs({
        input: Lt(),
        fatal: Lt(),
        expected: lg()
      }).concat(e, t);
    }
  };
}, Tg = function(e, t) {
  return t === void 0 && (t = 0), {
    buffer: e,
    cursor: t
  };
}, $g = function(e) {
  return og(e.cursor, e.buffer);
}, Ag = function(e) {
  return e.cursor >= e.buffer.length;
}, wg = function(e) {
  return g($g(e), Jt(function(t) {
    return { value: t, next: { buffer: e.buffer, cursor: e.cursor + 1 } };
  }));
}, ee = globalThis && globalThis.__assign || function() {
  return ee = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (e[a] = t[a]);
    }
    return e;
  }, ee.apply(this, arguments);
}, Or = function(e) {
  return function(t) {
    return Ge(e, t, t);
  };
}, Sg = function() {
  return function(e) {
    return ze(e);
  };
}, xg = function(e) {
  return function() {
    return ze(e);
  };
}, k = function(e) {
  return g(kg(Eg()), le(function(t) {
    var r = t[0], n = t[1];
    return e(r) ? ge(r) : xg(n);
  }));
}, O = function(e, t) {
  return function(r) {
    return g(e(r), It(function(n) {
      return mg(n, [t]);
    }));
  };
}, Eg = function() {
  return function(e) {
    return g(wg(e), xr(function() {
      return ze(e);
    }, function(t) {
      var r = t.value, n = t.next;
      return Ge(r, n, e);
    }));
  };
}, Og = function(e, t) {
  return function(r) {
    return g(e(r), On(function(n) {
      return g(t(n.value)(n.next), On(function(a) {
        return Ge(a.value, a.next, r);
      }));
    }));
  };
}, Nt = function(e, t) {
  return function(r) {
    var n = e(r);
    return yg(n) || n.left.fatal ? n : g(t()(r), It(function(a) {
      return _g(n.left, a);
    }));
  };
}, kg = function(e) {
  return function(t) {
    return g(e(t), Be(function(r) {
      return ee(ee({}, r), { value: [r.value, t] });
    }));
  };
}, Za = function(e) {
  return de(function() {
    return ge(e.empty);
  });
}, kr = function() {
  return O(function(e) {
    return Ag(e) ? Ge(void 0, e, e) : ze(e);
  }, "end of file");
}, jg = function(e) {
  return g(jr(e), de(function() {
    return ge([]);
  }));
}, jr = function(e) {
  return g(e, le(function(t) {
    return Rr(sg(t), function(r) {
      return g(e, E(function(n) {
        return re(ig(n)(r));
      }), de(function() {
        return ge(B(r));
      }));
    });
  }));
}, Cg = function(e, t) {
  return function(r) {
    return g(e, le(function() {
      return r;
    }), Ir(function() {
      return t;
    }));
  };
}, Da = function(e) {
  return Cg(e, e);
}, Rg = function(e) {
  return jg(k(te(e)));
}, Cr = function(e, t) {
  return g(t, E(function() {
    return rg;
  }), de(function() {
    return Ig(e, t);
  }));
}, Ig = function(e, t) {
  return g(e, le(function(r) {
    return Rr(Ss(r), function(n) {
      return g(t, E(function() {
        return B(n);
      }), de(function() {
        return g(e, E(function(a) {
          return re(Yy(a)(n));
        }));
      }));
    });
  }));
}, Ke = function(e, t) {
  return function(r) {
    return g(e(r), Be(function(n) {
      return ee(ee({}, n), { value: t(n.value) });
    }));
  };
}, Ua = function(e, t) {
  return Pt(e, function(r) {
    return Ke(t, r);
  });
}, Pt = function(e, t) {
  return Og(e, t);
}, Rr = function(e, t) {
  var r = function(n) {
    return function(a) {
      return ye(a.value) ? re({ value: a.value.left, stream: a.next }) : B(Ge(a.value.right, a.next, n));
    };
  };
  return function(n) {
    return hg({ value: e, stream: n }, function(a) {
      var s = t(a.value)(a.stream);
      return ye(s) ? B(ze(a.stream, s.left.expected, s.left.fatal)) : r(n)(s.right);
    });
  };
}, Ng = function(e, t) {
  return Nt(e, t);
}, E = function(e) {
  return function(t) {
    return Ke(t, e);
  };
}, ge = Or, le = function(e) {
  return function(t) {
    return Pt(t, e);
  };
}, Ir = function(e) {
  return function(t) {
    return Pt(t, function(r) {
      return Ke(e(r), function() {
        return r;
      });
    });
  };
}, de = function(e) {
  return function(t) {
    return Ng(t, e);
  };
}, Pg = "Parser", Lg = function(e) {
  return {
    concat: function(t, r) {
      return Ua(Ke(t, function(n) {
        return function(a) {
          return e.concat(n, a);
        };
      }), r);
    }
  };
}, Mg = function(e) {
  return ee(ee({}, Lg(e)), { empty: Or(e.empty) });
}, Vg = {
  URI: Pg,
  map: Ke,
  ap: Ua,
  chain: Pt,
  chainRec: Rr
}, qa = function(e, t, r) {
  var n;
  return Object.assign({}, e, (n = {}, n[t] = r, n));
}, Ha = function(e) {
  return function(t) {
    return g(t, E(function(r) {
      return qa({}, e, r);
    }));
  };
}, Yt = function(e, t) {
  return function(r) {
    return g(r, le(function(n) {
      return g(t(n), E(function(a) {
        return qa(n, e, a);
      }));
    }));
  };
}, Zg = function(e) {
  return _s(e)(e.empty);
};
bs.concat;
var Dg = Za(tr), me = function(e) {
  return O(k(function(t) {
    return t === e;
  }), '"'.concat(e, '"'));
}, Ug = function(e) {
  return O(k(function(t) {
    return t !== e;
  }), 'anything but "'.concat(e, '"'));
}, Nr = function(e) {
  return Dg(Fe(e));
}, Fe = function(e) {
  return g(jr(e), E(function(t) {
    return t.join("");
  }));
}, Pr = function(e) {
  return "0123456789".indexOf(e) !== -1;
}, Xt = O(k(Pr), "a digit"), qg = /^\s$/, Ba = function(e) {
  return qg.test(e);
}, Ga = O(k(Ba), "a whitespace"), Hg = function(e) {
  return e === "_";
}, Qe = function(e) {
  return /[a-z]/.test(e.toLowerCase());
}, za = function(e) {
  return Qe(e) || Pr(e) || Hg(e);
};
O(k(za), "a word character");
O(k(Qe), "a letter");
var Bg = function(e) {
  return e.toLowerCase() !== e.toUpperCase();
};
O(k(Bg), "an unicode letter");
var Ka = function(e) {
  return Qe(e) && e === e.toUpperCase();
};
O(k(Ka), "an upper case letter");
var Fa = function(e) {
  return Qe(e) && e === e.toLowerCase();
};
O(k(Fa), "a lower case letter");
O(k(te(Pr)), "a non-digit");
var Qa = O(k(te(Ba)), "a non-whitespace character");
O(k(te(za)), "a non-word character");
O(k(te(Qe)), "a non-letter character");
O(k(te(Ka)), "anything but an upper case letter");
O(k(te(Fa)), "anything but a lower case letter");
var Wa = function(e) {
  return O(Vg.chainRec(e, function(t) {
    return g(Kg(0, t), xr(function() {
      return ge(B(e));
    }, function(r) {
      return g(me(r), le(function() {
        return ge(re(t.slice(1)));
      }));
    }));
  }), JSON.stringify(e));
}, er = Zg(Mg(tr)), De = Za(tr), Gg = function(e) {
  return De(zg(e));
}, zg = function(e) {
  return g(jr(e), E(function(t) {
    return t.join("");
  }));
}, Kg = function(e, t) {
  return e >= 0 && e < t.length ? He(t.charAt(e)) : Rt;
}, Fg = Nr(Ga);
Fe(Ga);
Nr(Qa);
Fe(Qa);
var Qg = function(e) {
  var t = +e;
  return isNaN(t) || e === "" ? Rt : He(t);
};
O(g(er([De(me("-")), Fe(Xt)]), E(function(e) {
  return +e;
})), "an integer");
O(g(er([De(me("-")), Nr(Xt), De(er([me("."), Fe(Xt)]))]), le(function(e) {
  return g(Qg(e), xr(function() {
    return Sg();
  }, Or));
})), "a float");
var Wg = Da(me('"'))(Gg(Nt(Wa('\\"'), function() {
  return Ug('"');
})));
function Ja(e) {
  return function(t) {
    return t(Tg(e.split("")));
  };
}
const kn = (e, t) => (r) => ({
  ...r,
  [e]: t(r[e])
}), Jg = ["#", ":"], _e = Da(Fg), Lr = (e) => (t) => t.join(e), Mr = (e) => g(
  Rg((t) => e.includes(t)),
  E(Lr(""))
), Yg = W(
  Mr,
  Ir(() => k(() => !0))
), Ya = g(
  Wg,
  E((e) => JSON.parse(`"${e}"`))
), Xg = g(
  _e(Ya),
  de(
    () => g(
      Mr([":", `
`]),
      E(Ln)
    )
  )
), em = g(
  _e(Ya),
  de(
    () => g(
      Mr([`
`]),
      E(Ln)
    )
  )
), Xa = g(
  De(Wa("#")),
  E(te(Mn))
), es = g(
  _e(Xa),
  Ha("commented"),
  Yt("key", () => _e(Xg)),
  Ir(() => me(":")),
  Yt("value", () => em)
), tm = g(
  _e(Xa),
  Ha("commented"),
  Yt("key", () => Nt(
    Yg([`
`]),
    () => g(
      Cr(k((e) => !0), kr()),
      E(W(
        La,
        Lr("")
      ))
    )
  )),
  E(W(
    Ma(({ key: e }) => !Mn(e))
  ))
), rm = g(
  Cr(_e(es), kr())
), nm = g(
  Cr(
    Nt(
      g(es, E(He)),
      () => g(
        tm,
        E(W(
          Jt((e) => ({ ...e, value: "" }))
        ))
      )
    ),
    kr()
  ),
  E(W(
    tg(W(
      Ma(dg),
      Jt((e) => e.value)
    ))
  ))
), am = (e) => {
  if (Jg.some((n) => e.includes(n)))
    return !0;
  const t = JSON.stringify(e);
  return t.substring(1, t.length - 1).trim() !== e;
}, jn = (e) => am(e) ? JSON.stringify(e) : e, Tm = (e) => g(
  e,
  cg(
    W(
      kn("key", jn),
      kn("value", jn),
      ({ key: t, value: r, active: n }) => n ? `${t}: ${r}` : `# ${t}: ${r}`
    )
  ),
  Lr(`
`)
), sm = (e) => g(
  nm,
  Ja(e),
  It((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  Be(
    ({ value: t }) => g(
      t,
      Pa(
        ({ key: r, value: n, commented: a }) => ({
          active: !a,
          key: r,
          value: n
        })
      )
    )
  )
), $m = (e) => g(
  rm,
  Ja(e),
  It((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  Be(
    ({ value: t }) => g(
      t,
      Pa(
        ({ key: r, value: n, commented: a }) => ({
          active: !a,
          key: r,
          value: n
        })
      )
    )
  )
), Am = W(
  sm,
  Be(La),
  Er(() => [])
), im = i.object({
  id: i.optional(i.string()),
  name: i.string(),
  variables: i.array(
    i.object({
      key: i.string(),
      value: i.string()
    })
  )
}), Cn = M({
  initial: !0,
  schema: im
}), ts = () => Math.random().toString(36).substring(2, 16), om = i.object({
  v: i.literal(1),
  id: i.string(),
  name: i.string(),
  variables: i.array(
    i.union([
      i.object({
        key: i.string(),
        secret: i.literal(!0)
      }),
      i.object({
        key: i.string(),
        value: i.string(),
        secret: i.literal(!1).catch(!1)
      })
    ])
  )
}), cm = M({
  initial: !1,
  schema: om,
  up(e) {
    return {
      ...e,
      v: 1,
      id: e.id || ts(),
      variables: e.variables.map((r) => ({
        ...r,
        secret: !1
      }))
    };
  }
}), um = i.object({
  v: i.number()
}), wm = kt({
  latestVersion: 1,
  versionMap: {
    0: Cn,
    1: cm
  },
  getVersion(e) {
    const t = um.safeParse(e);
    return t.success ? t.data.v : Cn.schema.safeParse(e).success ? 0 : null;
  }
}), gt = /<<([^>]*)>>/g, mt = 10, rs = "ENV_EXPAND_LOOP", Rn = 1;
function lm(e, t) {
  let r = e, n = 0;
  for (; r.match(gt) != null && n <= mt; )
    r = r.replace(gt, (a) => {
      const s = t.find(
        (o) => o.key === a.replace(/[<>]/g, "")
      );
      return s && "value" in s ? s.value : a;
    }), n++;
  return n > mt ? re(rs) : B(r);
}
const Sm = (e, t) => g(
  lm(e, t),
  Er(() => e)
);
function dm(e, t, r = !1) {
  if (!t || !e)
    return B(e);
  let n = e, a = 0;
  for (; n.match(gt) != null && a <= mt; )
    n = decodeURI(encodeURI(n)).replace(gt, (s, o) => {
      const c = t.find((l) => l && l.key === o);
      return c && "value" in c ? c.secret && r ? "*".repeat(
        c.value.length
      ) : c.value : "";
    }), a++;
  return a > mt ? re(rs) : B(n);
}
const xm = (e, t, r = !1) => g(
  dm(e, t, r),
  Er(() => e)
), fm = (e) => ({
  key: e.key,
  value: e.value,
  secret: !1
}), Em = (e) => {
  if (e.v && e.v === Rn)
    return e;
  const t = e.id || ts(), r = e.name ?? "Untitled", n = (e.variables ?? []).map(fm);
  return {
    v: Rn,
    id: t,
    name: r,
    variables: n
  };
};
export {
  cv as AuthCodeGrantTypeParams,
  uv as ClientCredentialsGrantTypeParams,
  Qy as CollectionSchemaVersion,
  wm as Environment,
  Rn as EnvironmentSchemaVersion,
  ma as FormDataKeyValue,
  _r as GQLHeader,
  ja as GQL_REQ_SCHEMA_VERSION,
  bm as HoppCollection,
  ka as HoppGQLAuth,
  Vy as HoppGQLAuthAPIKey,
  Tr as HoppGQLAuthBasic,
  $r as HoppGQLAuthBearer,
  wr as HoppGQLAuthInherit,
  br as HoppGQLAuthNone,
  jt as HoppGQLAuthOAuth2,
  Sr as HoppGQLRequest,
  mr as HoppRESTAuth,
  hv as HoppRESTAuthAPIKey,
  pr as HoppRESTAuthBasic,
  hr as HoppRESTAuthBearer,
  yr as HoppRESTAuthInherit,
  fr as HoppRESTAuthNone,
  jt as HoppRESTAuthOAuth2,
  gr as HoppRESTHeaders,
  _a as HoppRESTReqBody,
  pm as HoppRESTReqBodyFormData,
  Ct as HoppRESTRequest,
  $a as HoppRESTRequestVariables,
  dv as ImplicitOauthFlowParams,
  lv as PasswordGrantTypeParams,
  Oy as RESTReqSchemaVersion,
  hm as ValidContentTypesList,
  Hy as getDefaultGQLRequest,
  ky as getDefaultRESTRequest,
  vm as isEqualHoppRESTRequest,
  mm as isHoppRESTRequest,
  Sy as knownContentTypes,
  Ia as makeCollection,
  _m as makeGQLRequest,
  gm as makeRESTRequest,
  Sm as parseBodyEnvVariables,
  lm as parseBodyEnvVariablesE,
  Am as parseRawKeyValueEntries,
  sm as parseRawKeyValueEntriesE,
  xm as parseTemplateString,
  dm as parseTemplateStringE,
  Tm as rawKeyValueEntriesToString,
  ym as safelyExtractRESTRequest,
  $m as strictParseRawKeyValueEntriesE,
  By as translateToGQLRequest,
  Em as translateToNewEnvironment,
  fm as translateToNewEnvironmentVariables,
  Jy as translateToNewGQLCollection,
  Wy as translateToNewRESTCollection,
  jy as translateToNewRequest
};
